import { IInsightsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    searchInsightsTypes: (state: IInsightsState) => state.insightsTypes,
    searchInsightsTypesLoaded: (state: IInsightsState) => state.insightsTypesLoaded,
    searchInsightsData: (state: IInsightsState) => state.insightsData,
    searchInsightsFavouritesLoading: (state: IInsightsState) => state.insightsFavouritesLoading,
};

const {read} = getStoreAccessors<IInsightsState, State>('');

export const readInsightsTypes = read(getters.searchInsightsTypes);
export const readInsightsTypesLoaded = read(getters.searchInsightsTypesLoaded);
export const readInsightsData = read(getters.searchInsightsData);
export const readInsightsFavouritesLoading = read(getters.searchInsightsFavouritesLoading);
