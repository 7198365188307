import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { Store } from 'vuex';
import { State } from '../state';

// API
import { api } from '@/api';
import { IActionLogState } from './state';
import { commitSetActionProjects, commitSetPageCount, commitSetProjectsPerLifecycle, commitSetTotalProjects } from './mutations';
import { IActionApprovalFlowCreate, IActionComment, IActionCommentCreateEdit, IActionProject, IActionProjectCreate, IActionsLifecycles, IActionsNonFinancialBenefitsPositionCreateUpdate, IActionsSavingsPositionCreateUpdate, IActionTask, IActionTaskCreateUpdate, IActionValidApprovalFlows } from '@/interfaces/actions';
import { dispatchShowAxiosErrorToast, dispatchShowErrorToast, dispatchShowStandardErrorToast } from '../app/actions';
import { getDimensionFilters, lifecyclesGetLifecycleFilter, lifecyclesGetParamsForProjects } from '@/functions/researchFilterFunctions';
import { readActionLogCurrrentPage, readActionLogItemsPerPage } from './getters';


type ActionLogContext = ActionContext<IActionLogState, State>;

export const actions = {
    async loadActionLogProjects(context: ActionLogContext, payload: {disablePagination: boolean}) {
        try {
            let filterFields = getDimensionFilters(context as any, false, false)[0];
            let filterValues = getDimensionFilters(context as any, false, false)[1];
            let filterFieldsNE = getDimensionFilters(context as any, false, false)[2];
            let filterValuesNE = getDimensionFilters(context as any, false, false)[3];

            // add lifecycle filter
            let lifecycleFilter = lifecyclesGetLifecycleFilter(context as any);
            filterFields = filterFields.concat(lifecycleFilter.filter_fields);
            filterValues = filterValues.concat(lifecycleFilter.filter_values);
            filterFieldsNE = filterFieldsNE.concat(lifecycleFilter.ne_filter_fields);
            filterValuesNE = filterValuesNE.concat(lifecycleFilter.ne_filter_values);

            // pagination
            let skip: number = 0;
            let limit: number = -1;

            if (!payload.disablePagination) {
                skip = (readActionLogCurrrentPage(context) > 1) ? (readActionLogCurrrentPage(context) - 1) * readActionLogItemsPerPage(context) : 0;
                limit = readActionLogItemsPerPage(context);
            }
            
            const response = await api.actionsProjects(
                context.rootState.main.token,
                undefined,
                undefined,
                filterFields,
                filterValues,
                filterFieldsNE,
                filterValuesNE,
                skip,
                limit,
            );
            if (response.data) {
                // sort by created_at
                let resp = response.data;
                resp.sort((a, b) => (a.created_at && b.created_at) ? b.created_at.localeCompare(a.created_at) : 0);
                commitSetActionProjects(context, resp);
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async loadActionLogProjectsMeta(context: ActionLogContext){
        try {
            let filterFields = getDimensionFilters(context as any, false, false)[0];
            let filterValues = getDimensionFilters(context as any, false, false)[1];
            
            const response = await api.actionsProjectsMeta(
                context.rootState.main.token,
                filterFields,
                filterValues,
            );
            if (response.data) {
                commitSetTotalProjects(context, response.data.count);
                let lifecycleCounts: object[] = [];
                lifecycleCounts.push({'lifecycle': 'ALL', 'count': response.data.count});
                for (const [key, value] of Object.entries(response.data.meta_per_status)) {
                    lifecycleCounts.push({
                        'lifecycle': key,
                        'count': value['count'],
                    })
                }
                commitSetProjectsPerLifecycle(context, lifecycleCounts);
            } else {
                return NaN;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
            return NaN;
        }
    },
    // CRUD projects
    async addActionLogProject(context: ActionLogContext, payload: IActionProjectCreate) {
        try {
            const response = await api.actionsCreateProject(context.rootState.main.token, payload);
            if (response.data) {
                await dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }    
        } catch (err) { 
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async editActionLogProject(context: ActionLogContext, payload: {projectID: string, project: IActionProjectCreate}) {
        try {
            let proj: IActionProjectCreate = payload.project;
            delete proj.approval_flow; // TODO needed? 
            const response = await api.actionsEditProject(context.rootState.main.token, payload.projectID, proj);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }    
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async editActionLogProjectLifecycle(context: ActionLogContext, payload: {projectID: string, lifecyle: IActionsLifecycles}) {
        try {
            const response = await api.actionsEditProjectLifecycle(context.rootState.main.token, payload.projectID, payload.lifecyle);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }    
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async deleteActionLogProject(context: ActionLogContext, payload: string) {
        try {
            const response = await api.actionsDeleteProject(context.rootState.main.token, payload);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
            }    
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    // CRUD tasks
    async addActionLogTask(context: ActionLogContext, payload: {projectID: string, task: IActionTaskCreateUpdate}) {
        try {
            const response = await api.actionsCreateTask(context.rootState.main.token, payload.projectID, payload.task);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async reorderActionLogTasks(context: ActionLogContext, payload: {projectID: string, idOrderArray: string[]}) {
        try {
            const response = await api.actionsReorderTasks(context.rootState.main.token, payload.projectID, payload.idOrderArray);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async editActionLogTask(context: ActionLogContext, payload: {projectID: string, taskID: string, task: IActionTask}) {
        try {
            const response = await api.actionsEditTask(context.rootState.main.token, payload.projectID, payload.taskID, payload.task);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }    
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async deleteActionLogTask(context: ActionLogContext, payload: {projectID: string, taskID: string}) {
        try {
            const response = await api.actionsDeleteTask(context.rootState.main.token, payload.projectID, payload.taskID);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
            }    
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    // CRUD comments
    async addActionLogComment(context: ActionLogContext, payload: {projectID: string, comment: IActionCommentCreateEdit}) {
        try {
            const response = await api.actionsCreateComment(context.rootState.main.token, payload.projectID, payload.comment);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }
        } catch (err) { 
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async editActionLogComment(context: ActionLogContext, payload: {projectID: string, commentID: string, comment: IActionComment}) {
        try {
            const response = await api.actionsEditComment(context.rootState.main.token, payload.projectID, payload.commentID, payload.comment);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }    
        } catch (err) { 
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    async deleteActionLogComment(context: ActionLogContext, payload: {projectID: string, commentID: string}) {
        try {
            const response = await api.actionsDeleteComment(context.rootState.main.token, payload.projectID, payload.commentID);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
            }    
        } catch (err) { 
            dispatchShowStandardErrorToast(context, {error: err});
        }
    },
    // Approvals
    async loadActionLogValidApprovalFlows(context: ActionLogContext, payload: {projectID: string}): Promise<IActionValidApprovalFlows[]> {
        try {
            const response = await api.actionFindValidApprovalFlows(context.rootState.main.token, payload.projectID);
            if (response.data) {
                return response.data;
            } else {
                return [];
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
            return [];
        }
    },
    async addActionLogApprovalFlow(context: ActionLogContext, payload: {projectID: string, approvalFlow: IActionApprovalFlowCreate}) {
        try {
            const response = await api.actionsCreateApprovalFlow(context.rootState.main.token, payload.projectID, payload.approvalFlow);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async startActionLogApprovalFlow(context: ActionLogContext, payload: {projectID: string, restart: boolean}) {
        try {
            const response = await api.actionsStartApprovalFlow(context.rootState.main.token, payload.projectID, payload.restart);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async approveActionLogApprovalFlow(context: ActionLogContext, payload: {projectID: string, deny: boolean, denyComment?: string}) {
        try {
            const response = await api.actionsApproveApprovalFlow(context.rootState.main.token, payload.projectID, payload.deny, payload.denyComment);
            if (response.data) {
                dispatchLoadActionLogProjects(context, {disablePagination: false});
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    // CRUD Savings positions
    async addActionLogSavingsPosition(context: ActionLogContext, payload: {projectID: string, savingsPosition: IActionsSavingsPositionCreateUpdate}) {
        try {
            const response = await api.actionsCreateSavingsPosition(context.rootState.main.token, payload.projectID, payload.savingsPosition);
            if (response.data) {
                // dispatchLoadActionLogProjects(context);
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async editActionLogSavingsPosition(context: ActionLogContext, payload: {projectID: string, savingsPositionID: string, savingsPosition: IActionsSavingsPositionCreateUpdate}) {
        try {
            const response = await api.actionsEditSavingsPosition(context.rootState.main.token, payload.projectID, payload.savingsPositionID, payload.savingsPosition);
            if (response.data) {
                //dispatchLoadActionLogProjects(context);
                return response.data;
            }
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
    async deleteActionLogSavingsPosition(context: ActionLogContext, payload: {projectID: string, savingsPositionID: string}) {
        try {
            const response = await api.actionsDeleteSavingsPosition(context.rootState.main.token, payload.projectID, payload.savingsPositionID);
            if (response.data) {
                return response.data;
            }    
        } catch (err) { 
            dispatchShowAxiosErrorToast(context, err);
        }
    },
        // CRUD benefits positions
        async addActionLogBenefitsPosition(context: ActionLogContext, payload: {projectID: string, benefitsPosition: IActionsNonFinancialBenefitsPositionCreateUpdate}) {
            try {
                const response = await api.actionsCreateBenefitsPosition(context.rootState.main.token, payload.projectID, payload.benefitsPosition);
                if (response.data) {
                    // dispatchLoadActionLogProjects(context);
                    return response.data;
                }
            } catch (err) { 
                dispatchShowAxiosErrorToast(context, err);
            }
        },
        async editActionLogBenefitsPosition(context: ActionLogContext, payload: {projectID: string, benefitsPositionID: string, benefitsPosition: IActionsNonFinancialBenefitsPositionCreateUpdate}) {
            try {
                const response = await api.actionsEditBenefitsPosition(context.rootState.main.token, payload.projectID, payload.benefitsPositionID, payload.benefitsPosition);
                if (response.data) {
                    //dispatchLoadActionLogProjects(context);
                    return response.data;
                }
            } catch (err) { 
                dispatchShowAxiosErrorToast(context, err);
            }
        },
        async deleteActionLogBenefitsPosition(context: ActionLogContext, payload: {projectID: string, benefitsPositionID: string}) {
            try {
                const response = await api.actionsDeleteBenefitsPosition(context.rootState.main.token, payload.projectID, payload.benefitsPositionID);
                if (response.data) {
                    return response.data;
                }    
            } catch (err) { 
                dispatchShowAxiosErrorToast(context, err);
            }
        },
};

const { dispatch } = getStoreAccessors<IActionLogState | any, State>('');

export const dispatchLoadActionLogProjects = dispatch(actions.loadActionLogProjects);
export const dispatchLoadActionLogProjectsMeta = dispatch(actions.loadActionLogProjectsMeta);
export const dispatchAddActionLogProject = dispatch(actions.addActionLogProject);
export const dispatchEditActionLogProject = dispatch(actions.editActionLogProject);
export const dispatchEditActionLogProjectLifecycle = dispatch(actions.editActionLogProjectLifecycle);
export const dispatchDeleteActionLogProject = dispatch(actions.deleteActionLogProject);
export const dispatchAddActionLogTask = dispatch(actions.addActionLogTask);
export const dispatchReorderActionLogTasks = dispatch(actions.reorderActionLogTasks);
export const dispatchEditActionLogTask = dispatch(actions.editActionLogTask);
export const dispatchDeleteActionLogTask = dispatch(actions.deleteActionLogTask);
export const dispatchAddActionLogComment = dispatch(actions.addActionLogComment);
export const dispatchEditActionLogComment = dispatch(actions.editActionLogComment);
export const dispatchDeleteActionLogComment = dispatch(actions.deleteActionLogComment);
export const dispatchLoadActionLogValidApprovalFlows = dispatch(actions.loadActionLogValidApprovalFlows);
export const dispatchAddActionLogApprovalFlow = dispatch(actions.addActionLogApprovalFlow);
export const dispatchStartActionLogApprovalFlow = dispatch(actions.startActionLogApprovalFlow);
export const dispatchApproveActionLogApprovalFlow = dispatch(actions.approveActionLogApprovalFlow);
export const dispatchAddActionSavingsPosition = dispatch(actions.addActionLogSavingsPosition);
export const dispatchEditActionLogSavingsPosition = dispatch(actions.editActionLogSavingsPosition);
export const dispatchDeleteActionLogPosition = dispatch(actions.deleteActionLogSavingsPosition);
export const dispatchAddActionBenefitsPosition = dispatch(actions.addActionLogBenefitsPosition);
export const dispatchEditActionLogBenefitsPosition = dispatch(actions.editActionLogBenefitsPosition);
export const dispatchDeleteActionLogBenefitsPosition = dispatch(actions.deleteActionLogBenefitsPosition);
