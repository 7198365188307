import { IActionLogState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    searchActionLogProjects: (state: IActionLogState) => state.actionProjects,
    searchActionLogSelectedLifecycles: (state: IActionLogState) => state.selectedLifecycles,
    searchActionLogTotalProjects: (state: IActionLogState) => state.totalProjects,
    searchActionLogProjectsPerLifecycle: (state: IActionLogState) => state.projectsPerLifecycle,
    searchActionLogPageCount: (state: IActionLogState) => state.pageCount,
    searchActionLogItemsPerPage: (state: IActionLogState) => state.itemsPerPage,
    searchActionLogCurrentPage: (state: IActionLogState) => state.currentPage,
};

const {read} = getStoreAccessors<IActionLogState, State>('');

export const readActionLogProjects = read(getters.searchActionLogProjects);
export const readActionLogSelectedLifecycles = read(getters.searchActionLogSelectedLifecycles);
export const readActionLogTotalProjects = read(getters.searchActionLogTotalProjects);
export const readActionLogProjectsPerLifecycle = read(getters.searchActionLogProjectsPerLifecycle);
export const readActionLogPageCount = read(getters.searchActionLogPageCount);
export const readActionLogItemsPerPage = read(getters.searchActionLogItemsPerPage);
export const readActionLogCurrrentPage = read(getters.searchActionLogCurrentPage);
