import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IUserProfileCreate, IUserProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { dispatchShowSuccessToast } from '@/store/app/actions';
import { AxiosError } from 'axios';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    async actionGetUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error as AxiosError);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: string, user: IUserProfileUpdate }) {
        try {
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            dispatchShowSuccessToast(context, 'User geändert');
        } catch (error) {
            await dispatchCheckApiError(context, error as AxiosError);
        }
    },
    async actionCreateUser(context: MainContext, payload: {profile: IUserProfileCreate, send_email: boolean}) {
        try {
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload.profile, payload.send_email),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            dispatchShowSuccessToast(context, 'User angelegt');
        } catch (error) {
            await dispatchCheckApiError(context, error as AxiosError);
        }
    },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
