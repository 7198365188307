export default {
  // current currency
  currency: {
    label: 'EUR',
    decimalDigits: 0,
    decimalSeparator: ',',
    thousandsSeparator: '.',
    currencySymbol: '€',
    currencySymbolNumberOfSpaces: 1,
    currencySymbolPosition: 'right',
  },

  // availabled currencies for user selection
  availableCurrencies: [
    {
      label: 'USD',
      decimalDigits: 0,
      decimalSeparator: '.',
      thousandsSeparator: ',',
      currencySymbol: '$',
      currencySymbolNumberOfSpaces: 0,
      currencySymbolPosition: 'left',
    }, 
    {
      label: 'EUR',
      decimalDigits: 0,
      decimalSeparator: ',',
      thousandsSeparator: '.',
      currencySymbol: '€',
      currencySymbolNumberOfSpaces: 1,
      currencySymbolPosition: 'right',
    },
    {
      label: 'QAR',
      decimalDigits: 0,
      decimalSeparator: '.',
      thousandsSeparator: ',',
      currencySymbol: 'QR',
      currencySymbolNumberOfSpaces: 1,
      currencySymbolPosition: 'left',
    },
    {
      label: 'CHF',
      decimalDigits: 0,
      decimalSeparator: ',',
      thousandsSeparator: '.',
      currencySymbol: 'CHF',
      currencySymbolNumberOfSpaces: 1,
      currencySymbolPosition: 'right',
    }
  ],
};
