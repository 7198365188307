import { ICategoryLabState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { ICategorylabSchema } from '@/interfaces/categoryLab';


export const mutations = {
    setCategoryLabSchema(state: ICategoryLabState, payload: ICategorylabSchema[]) {
        state.schema = payload;
    },
    setAvailableCategoryLabSchema(state: ICategoryLabState, payload: ICategorylabSchema[]) {
        state.availableSchema = payload;
    },
    setCategoryLabSchemaLoaded(state: ICategoryLabState, payload: boolean) {
        state.schemaLoaded = payload;
    },
};

const {commit} = getStoreAccessors<ICategoryLabState | any, State>('');

export const commitSetCategoryLabSchema = commit(mutations.setCategoryLabSchema);
export const commitSetAvailableCategoryLabSchema = commit(mutations.setAvailableCategoryLabSchema);
export const commitSetCategoryLabSchemaLoaded = commit(mutations.setCategoryLabSchemaLoaded);
