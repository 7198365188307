// OTHER
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '@/env';

// INTERFACES
import {
    IUserProfile,
    IUserProfileUpdate,
    IUserProfileCreate,
    IOrganizationUpdate,
    IOrganizationCreate,
} from './interfaces';

import {
    ISchemaDistinctElement,
} from './interfaces/schema';

import {
    IClusterSchema
} from './interfaces/cluster';

import {
    IAggregationSchema
} from './interfaces/aggregation';

import {
    IDataManagementFileAttributeCheck,
} from './interfaces/dataManagement';
import {
    ILUTRow,
    ILUTRowCreate,
    ILUTRowUpdate,
    ILUTConfig,
    ILUTConfigCreate,
    ILUTConfigUpdate,
} from './interfaces/lut';

import {
    IResearchAPIPostPayload,
    IResearchAPIPostPayloadWOrder,
    IResearchSpendPerTime,
    ISalesCaseCompany,
    ISalesCaseCompanyCreateEdit,
    IResearchCategoryData,
} from './interfaces/research';

// STORES
import {
    IResearchShowCategoryTypes,
} from './store/research/state';
import {
    IView,
    IViewCreate,
} from './interfaces/views';
import {
    IKPIAvailableTimeRanges,
    IKPIMeta,
    IKPIValueTypes,
} from './interfaces/kpi';
import {
    ISavingsPosition,
    ISavingsPositionField,
    ISavingsPositionMeta,
    ISavingsPositionSavings,
    ISavingsPositionShareCategory,
} from '@/interfaces/savings';
import {
    IKPIBenchmark,
    IKPIInsight,
    IKPIInsightMeta,
    IKPITarget,
    IKPITargetCreateUpdate,
} from './interfaces/kpiTargets';
import { IFact, IFactCreateUpdate } from './interfaces/facts';
import { IETLSchema } from './interfaces/etl';
import {
    ICategoryLabMetaResponse,
    ICategorylabRow,
    ICategorylabRule,
    ICategorylabRuleChangeObjectAPI,
    ICategorylabSchema,
    ICategoryLabRuleEdit,
} from './interfaces/categoryLab';
import { IInsightsResult } from './interfaces/insights';

import { spendAttributeAddRandomID } from './functions/researchDataHelperFunctions';
import { IActionApprovalFlow, IActionApprovalFlowCreate, IActionComment, IActionCommentCreateEdit, IActionProject, IActionProjectCreate, IActionProjectsMetaResponse, IActionsLifecycles, IActionsNonFinancialBenefitsPosition, IActionsNonFinancialBenefitsPositionCreateUpdate, IActionsSavingsPositionCreateUpdate, IActionTask, IActionTaskCreateUpdate, IActionValidApprovalFlows } from './interfaces/actions';


function authHeaders(token: string): AxiosRequestConfig {
    if (process.env.NODE_ENV === 'development') {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                client: 'test',
            },
        };
    } else {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    }
}

function authHeadersBlob(token: string): AxiosRequestConfig {
    const header = authHeaders(token);
    header.responseType = 'blob';
    return header;
}

function getCategoryShowTypeURLParams(showType: IResearchShowCategoryTypes): string {
    let url = '';
    if (showType === 'DISABLE') {
        url = `category_best=false&category_best_by_aggregation=false`;
    } else if (showType === 'TRANSACTION_BASED') {
        url = `category_best=true&category_best_by_aggregation=false`;
    } else if (showType === 'AGGREGATION_BASED') {
        url = `category_best=false&category_best_by_aggregation=true`;
    }
    return url;
}

function getCategoryShowTypeEnum(showType: IResearchShowCategoryTypes): string {
    let enumStr = '';
    if (showType === 'DISABLE') {
        enumStr = `CUSTOMER`;
    } else if (showType === 'TRANSACTION_BASED') {
        enumStr = `BEST`;
    } else if (showType === 'AGGREGATION_BASED') {
        enumStr = `BEST_AGGREGATED`;
    }
    return enumStr;
}

function getFieldsParams(fields: string[]) {
    let paramURL = '';
    fields.forEach((field) => {
        paramURL = `${paramURL}&fields=${field}`;
    });
    return paramURL;
}

function getFurtherFieldsParams(fields: string[]) {
    let paramURL = '';
    fields.forEach((field) => {
        paramURL = `${paramURL}&further_share_fields=${field}`;
    });
    return paramURL;
}

function savingsUserRoleManagement(showAll: boolean, userProfile: IUserProfile | null, filterFields: string[], filterValues: string[][]) {
    if (!showAll) {
        // get user email and append it to filter arrays
        let email = userProfile?.email;
        if (email) {
            filterFields.push('responsible_email');
            filterValues.push([email]);
        }
    }
    return { fields: filterFields, values: filterValues };
}

export const api = {
    async logInGetToken(username: string, password: string) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        if (process.env.NODE_ENV === 'development') {
            return axios.post(`${apiUrl}/api/v1/login/access-token/`, params, { headers: { client: 'test' } });
        } else {
            return axios.post(`${apiUrl}/api/v1/login/access-token/`, params);
        }
    },
    async getTicket(token: string) {
        return axios.get(`${apiUrl}/api/v1/ticket/`, authHeaders(token));
    },
    async logInTestToken(token: string) {
        return axios.get<IUserProfile>(`${apiUrl}/api/v1/login/test-token/`, authHeaders(token));
    },
    async getMe(token: string) {
        // Call me/check to get user profile + additionaly check if permission for web app exists
        return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me/check/`, authHeaders(token));
    },
    async updateMe(token: string, data: IUserProfileUpdate) {
        return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me/`, data, authHeaders(token));
    },
    async getUsers(token: string, skip = 0, limit = 99999) {
        return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/?skip=${skip}&limit=${limit}`, authHeaders(token));
    },
    async updateUser(token: string, userId: string, data: IUserProfileUpdate) {
        return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
    },
    async createUser(token: string, data: IUserProfileCreate, send_email: boolean = true) {
        return axios.post(`${apiUrl}/api/v1/users/?send_email=${send_email}`, data, authHeaders(token));
    },
    async removeUser(token: string, userId: string) {
        return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
    },
    async passwordRecovery(email: string) {
        return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
    },
    async resetPassword(password: string, token: string) {
        return axios.post(`${apiUrl}/api/v1/reset-password/`, {
            new_password: password,
            token,
        });
    },
    async taskCheck(token: string, taskID: string) {
        return axios.get(`${apiUrl}/api/v1/utils/celery/check/${taskID}`, authHeaders(token));
    },
    // methods for admin organizations
    async getAllOrganizations(token: string) {
        return axios.get(`${apiUrl}/api/v1/organizations/meta/`, authHeaders(token));
    },
    async getSingleOrganization(token: string, dbName: string) {
        return axios.get(`${apiUrl}/api/v1/organizations/?db_name=${dbName}`, authHeaders(token));
    },
    async updateOrganization(token: string, dbName: string, payload: IOrganizationUpdate) {
        return axios.put(`${apiUrl}/api/v1/organizations/?db_name=${dbName}`, payload, authHeaders(token));
    },
    async createOrganization(token: string, payload: IOrganizationCreate) {
        return axios.post(`${apiUrl}/api/v1/organizations/`, payload, authHeaders(token));
    },
    // methods for data management
    async dataManagementReadFiles(token: string) {
        return axios.get(`${apiUrl}/api/v1/files/`, authHeaders(token));
    },
    async dataManagementReadFileScheme(token: string) {
        return axios.get(`${apiUrl}/api/v1/files/schema/`, authHeaders(token));
    },
    async dataManagementReadFileSchemeGlobal(token: string) {
        return axios.get(`${apiUrl}/api/v1/files/schema/global/`, authHeaders(token));
    },
    async dataManagementFileUpload(token: string, fileObject: any) {
        const formData = new FormData();
        formData.append('file', fileObject);
        const fileHeaders: AxiosRequestConfig = authHeaders(token);
        fileHeaders.headers = fileHeaders.headers || {};
        fileHeaders.headers['Content-Type'] = 'multipart/form-data';
        return axios.post(`${apiUrl}/api/v1/files/`, formData, fileHeaders);
    },
    async dataManagementFileTransfer(token: string, fileObject: any) {
        const formData = new FormData();
        formData.append('file', fileObject);
        const fileHeaders: AxiosRequestConfig = authHeaders(token);
        fileHeaders.headers = fileHeaders.headers || {};
        fileHeaders['headers']['Content-Type'] = 'multipart/form-data';
        return axios.post(`${apiUrl}/api/v1/files/oss/transfer/`, formData, fileHeaders);
    },
    async dataManagementPrepareCheck(token: string, fileID: string, payload: IDataManagementFileAttributeCheck) {
        return axios.post(`${apiUrl}/api/v1/files/${fileID}/prepare/check/`, payload, authHeaders(token));
    },
    async dataManagementPrepareView(token: string, fileID: string, payload: object) {
        return axios.post(`${apiUrl}/api/v1/files/${fileID}/prepare/view/`, payload, authHeaders(token));
    },
    async dataManagementPrepareFile(token: string, fileID: string, payload: object) {
        return axios.post(`${apiUrl}/api/v1/files/${fileID}/prepare/`, payload, authHeaders(token));
    },
    async dataManagementDeleteFile(token: string, fileID: string, fileName: string) {
        return axios.delete(`${apiUrl}/api/v1/files/${fileID}?file_name=${fileName}`, authHeaders(token));
    },
    async dataManagementExportFile(token: string, fileID: string) {
        return axios.get(`${apiUrl}/api/v1/files/download/${fileID}`, authHeaders(token));
    },

    // === START LUT ===
    async lutConfigGetKeyFields(token: string) {
        return axios.get(`${apiUrl}/api/v1/luts/configs/key_fields/`, authHeaders(token));
    },
    async lutConfigGetValueFields(token: string) {
        return axios.get(`${apiUrl}/api/v1/luts/configs/value_fields/`, authHeaders(token));
    },
    async lutConfigVerify(
        token: string,
        name: string,
    ) {
        return axios.get(`${apiUrl}/api/v1/luts/configs/verify/${name}`, authHeaders(token));
    },
    async lutConfigList(
        token: string,
        page: number,
        itemsPerPage: number,
        filterFields: string[] = [],
        filterValues: string[][] = [],
        searchString?: string,
        sortBy: string[] = [],
        sortOrder: number[] = [],
    ) {
        let url = `${apiUrl}/api/v1/luts/configs/filter/?skip=${(page - 1) * itemsPerPage}&limit=${page * itemsPerPage}`
        if (searchString && searchString != "") url = `${url}&search=${searchString}`
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            sort_by: sortBy,
            sort_order: sortOrder,
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async lutConfigDownloadFile(
        token: string,
        lutID: string,
    ) {
        return axios.get(`${apiUrl}/api/v1/luts/configs/${lutID}/download/`, authHeaders(token));
    },
    async lutRowList(
        token: string,
        lutID: string,
        page: number,
        itemsPerPage: number,
        searchString?: string,
        sortBy: string[] = [],
        sortOrder: number[] = [],
    ) {
        let url = `${apiUrl}/api/v1/luts/rows/${lutID}/filter/?skip=${(page - 1) * itemsPerPage}&limit=${page * itemsPerPage}`
        if (searchString && searchString !== '') url = `${url}&search=${searchString}`
        const payload: IResearchAPIPostPayload = {
            sort_by: sortBy,
            sort_order: sortOrder,
        }
        return axios.post<ILUTRow[]>(url, payload, authHeaders(token));
    },
    async lutRowMeta(
        token: string,
        lutID: string,
        searchString?: string,
    ) {
        let url = `${apiUrl}/api/v1/luts/rows/${lutID}/meta/`
        if (searchString && searchString !== '') url = `${url}?search=${searchString}`
        return axios.post(url, {}, authHeaders(token));
    },
    async lutEnumOptions(token: string, field: string, collection: string = 'transaction') {
        let url = `${apiUrl}/api/v1/schema/enum/${field}?collection=${collection}`;
        return axios.get<string[]>(url, authHeaders(token));
    },
    async lutConfigMeta(
        token: string,
        searchString: string,
    ) {
        let url = `${apiUrl}/api/v1/luts/configs/meta/`;
        if (searchString && searchString !== '') url = `${url}?search=${searchString}`
        return axios.post(url, {}, authHeaders(token));
    },
    async lutConfig(
        token: string,
        lutID: string,
    ) {
        return axios.get<ILUTConfig>(`${apiUrl}/api/v1/luts/configs/${lutID}`, authHeaders(token));
    },
    async lutRowDelete(
        token: string,
        rowID: string,
    ) {
        return axios.delete(`${apiUrl}/api/v1/luts/rows/${rowID}`, authHeaders(token));
    },
    async lutConfigDelete(
        token: string,
        lutID: string,
    ) {
        return axios.delete(`${apiUrl}/api/v1/luts/configs/${lutID}`, authHeaders(token));
    },
    async lutConfigCreate(
        token: string,
        lut: ILUTConfigCreate,
    ) {
        return axios.post(`${apiUrl}/api/v1/luts/configs/`, lut, authHeaders(token));
    },
    async lutConfigUpdate(
        token: string,
        lutID: string,
        lut: ILUTConfigUpdate,
    ) {
        return axios.put(`${apiUrl}/api/v1/luts/configs/${lutID}`, lut, authHeaders(token));
    },
    async lutRow(
        token: string,
        rowID: string,
    ) {
        return axios.get(`${apiUrl}/api/v1/luts/rows/${rowID}`, authHeaders(token));
    },
    async lutRowCreate(
        token: string,
        row: ILUTRowCreate,
    ) {
        const payload = {
            keys: row.keys,
            value: row.value,
        }
        return axios.post(`${apiUrl}/api/v1/luts/rows/${row.lut_config_id}`, payload, authHeaders(token));
    },
    async lutRowUpdate(
        token: string,
        rowID: string,
        row: ILUTRowUpdate,
    ) {
        const payload = {
            keys: row.keys,
            value: row.value,
        }
        return axios.put(`${apiUrl}/api/v1/luts/rows/${rowID}`, payload, authHeaders(token));
    },
    async lutConfigUploadFile(
        token: string,
        lutID: string,
        file: any,
        fileMappings: any,
    ) {
        const formData = new FormData();
        formData.append('file', file);
        if (Object.keys(fileMappings).length !== 0) {
            formData.append('file_field_to_column_str', JSON.stringify(fileMappings));
        }
        const fileHeaders: AxiosRequestConfig = authHeaders(token);
        fileHeaders.headers = fileHeaders.headers || {};
        fileHeaders['headers']['Content-Type'] = 'multipart/form-data';
        return axios.post(`${apiUrl}/api/v1/luts/configs/${lutID}/file/`, formData, fileHeaders);
    },
    async lutApplyAll(token: string) {
        return axios.get(`${apiUrl}/api/v1/luts/configs/apply/`, authHeaders(token));
    },
    async lutCheck(token: string) {
        return axios.get(`${apiUrl}/api/v1/luts/check/`, authHeaders(token));
    },
    // === END LUT ===

    async dataManagementExportTransactions(
        token: string,
        startDate?: string, endDate?: string,
        filterFields?: string[], filterValues?: string[][],
        filterFieldsNE?: string[], filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
        add_xlsx: boolean = true,
        add_csv: boolean = true,
        add_json: boolean = false,
    ) {
        let url = `${apiUrl}/api/v1/transactions/download/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&harmonize=${harmonize}`;
        url = `${url}&add_xlsx=${add_xlsx}&add_csv=${add_csv}&add_json=${add_json}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    // METHODS FOR CATEGORYLAB
    // Get count and share per hierarchy
    async categorizerSharePerHierarchy(
        token: string,
        categoryTypeFrom: IResearchShowCategoryTypes,
        startDate?: string, endDate?: string,
        filterFields?: string[], filterValues?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
    ) {
        let url = `${apiUrl}/api/v1/transactions/share/category_id/hierarchy/${getCategoryShowTypeEnum(categoryTypeFrom)}?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&harmonize=${harmonize}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async categorizerSharePerHierarchyExtended(
        token: string,
        startDate: string, endDate: string,
        filterFields?: string[], filterValues?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
        sortByTransaction = false
    ) {
        let url = `${apiUrl}/api/v1/transactions/share/category_ids/hierarchy/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&harmonize=${harmonize}`;
        url = `${url}&sort_by_transaction=${sortByTransaction}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async categorizerCompare(
        token: string,
        categoryTypeCompareFrom: IResearchShowCategoryTypes,
        categoryTypeCompareTo: IResearchShowCategoryTypes,
        startDate: string, endDate: string,
        filterFields?: string[], filterValues?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
    ) {
        let url = `${apiUrl}/api/v1/transactions/share/category_id/comparison/${getCategoryShowTypeEnum(categoryTypeCompareFrom)}/${getCategoryShowTypeEnum(categoryTypeCompareTo)}?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&harmonize=${harmonize}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async categoryLabCheck(token: string) {
        return axios.get(`${apiUrl}/api/v1/category_lab/check/`, authHeaders(token));
    },
    async categoryLabStart(
        token: string,
        startDate?: string, endDate?: string,
        filterFields?: string[], filterValues?: string[][],
        filterFieldsNE?: string[], filterValuesNE?: string[][],
        harmonize = false,
    ) {
        let url = `${apiUrl}/api/v1/category_lab/start/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&harmonize=${harmonize}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<string>(url, payload, authHeaders(token));
    },
    async categoryLabCheckSession(
        token: string,
        accessToken: string,
    ) {
        let url = `${apiUrl}/api/v1/category_lab/has_session/?token=${accessToken}`; // TODO change url
        return axios.get<Boolean>(url, authHeaders(token));
    },
    async categorylabStopTask(token: string, accessToken: string) {
        return axios.get(`${apiUrl}/api/v1/category_lab/stop/${accessToken}`, authHeaders(token));
    },
    async categoryLabGetRows(
        token: string,
        accessToken: string,
        skip: number,
        limit: number,
        filterFields?: string[], filterValues?: string[][],
        filterFieldsNE?: string[], filterValuesNE?: string[][],
        search?: string,
        sortBy?: string[],
        sortOrder?: number[],
    ) {
        let url = `${apiUrl}/api/v1/category_lab/filter/${accessToken}?skip=${skip}&limit=${limit}`;
        if (search) url = `${url}&search=${search}`;
        const payload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
            sort_by: (sortBy && sortBy.length > 0) ? sortBy : [],
            sort_order: (sortOrder && sortOrder.length > 0) ? sortOrder : [],
        }
        return axios.post<ICategorylabRow[]>(url, payload, authHeaders(token)); 
    },
    async categoryLabReadMeta(
        token: string,
        accessToken: string,
        filterFields?: string[], filterValues?: string[][],
        filterFieldsNE?: string[], filterValuesNE?: string[][],
    ) {
        let url = `${apiUrl}/api/v1/category_lab/meta/${accessToken}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<ICategoryLabMetaResponse>(url, payload, authHeaders(token));
    },
    async categoryLabChangeRule(token: string, categoryLabChangeObject: ICategorylabRuleChangeObjectAPI) {
        let url = `${apiUrl}/api/v1/category_lab/rules/`;
        return axios.post<ICategorylabRow[]>(url, categoryLabChangeObject, authHeaders(token));
    }, // TODO delete
    /*async categorizerAddRules(token: string, accessToken: string, transferObject: ICategorizerRuleAdd) {
        return axios.post(`${apiUrl}/api/v1/categorizer/rules/${accessToken}`, transferObject, authHeaders(token));
    }, // TODO delete*/
    async categorizerAddRow(token: string, accessToken: string, rowId: string, categoryId?: string, reset = false) {
        let url = `${apiUrl}/api/v1/categorizer/rules/transactions/${accessToken}?`;
        url = `${url}categorizer_row_id=${rowId}`;
        url = `${url}&reset=${reset}`;
        if (categoryId) {
            url = `${url}&category_id=${categoryId}`;
        }
        return axios.get(url, authHeaders(token));
    }, // TODO delete
    async categorizerUpdateRowCategory(token: string, categorizerRowID: string, categoryID?: string) {
        let url = `${apiUrl}/api/v1/categorizer/${categorizerRowID}/category/`;
        if (categoryID && categoryID !== '') url = `${url}?category_id=${categoryID}`;
        return axios.post(url, [], authHeaders(token));
    }, // TODO delete
    async categorizerPredictCategories(token: string, accessToken: string, preferredRows: string[]) {
        return axios.post(`${apiUrl}/api/v1/categorizer/predict/${accessToken}`, preferredRows, authHeaders(token));
    },
    async categorylabPredictTransactions(token: string) {
        return axios.get(`${apiUrl}/api/v1/category_lab/predict/transactions/`, authHeaders(token));
    },
    // methods for CategoryLab rules
    async rulesReadAll(
        token: string,
        skip = 0, limit = 10,
        filterFields?: string[], filterValues?: string[][],
        search = '',
    ) {
        let url = `${apiUrl}/api/v1/rules/filter/?skip=${skip}&limit=${limit}`;
        if (search && search !== '') url = `${url}&search=${search}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post<ICategorylabRule[]>(url, payload, authHeaders(token));
    },
    async rulesReadHierarchyShare(
        token: string,
        filterFields?: string[], filterValues?: string[][],
    ) {
        const url = `${apiUrl}/api/v1/rules/share/hierarchy/?`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async rulesReadAmbiguityCount(
        token: string,
        filterFields?: string[], filterValues?: string[][],
    ) {
        const url = `${apiUrl}/api/v1/rules/count/ambiguity/?`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async rulesReadMeta(
        token: string,
        filterFields?: string[], filterValues?: string[][],
        search = '',
    ) {
        let url = `${apiUrl}/api/v1/rules/meta/?`;
        if (search && search !== '') url = `${url}&search=${search}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post<number>(url, payload, authHeaders(token));
    },
    /*async rulesCreateRule(token: string, payload: iRuleCreateEdit) {
        return axios.post(`${apiUrl}/api/v1/rules/`, payload, authHeaders(token));
    },*/
    async rulesUpdateRule(token: string, ruleID: string, payload: ICategoryLabRuleEdit) {
        return axios.post(`${apiUrl}/api/v1/rules/${ruleID}`, payload, authHeaders(token));
    },
    async rulesDeleteRule(token: string, ruleID: string) {
        return axios.delete(`${apiUrl}/api/v1/rules/${ruleID}`, authHeaders(token));
    },
    // methods for llm
    async llmPredict(
        token: string,
        features: string[],
    ) {
        let url = `${apiUrl}/api/v1/llm/predict/?`;
        features.forEach((feature) => {
            url = `${url}&features=${feature}`;
        });
        return axios.get(url, authHeaders(token));
    },
    // methods for cluster
    async clusterReadMeta(token: string, collection: string) {
        return axios.get(`${apiUrl}/api/v1/clusters/meta/${collection}`, authHeaders(token));
    },
    async clusterReadAll(token: string, collection: string, skip = 0, limit = 500) {
        return axios.get(`${apiUrl}/api/v1/clusters/${collection}?skip=${skip}&limit=${limit}`, authHeaders(token));
    },
    // methods for spend analytics
    async analyticsTransactions(
        token: string,
        skip = 0,
        limit = 10,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        search?: string,
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        sortBy: string[] = ["timestamp"],
        sortOrder: number[] = [-1],
        includeChildrens = true,
        harmonize = false
    ) {
        let url = `${apiUrl}/api/v1/transactions/filter/?skip=${skip}&limit=${limit}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        // Set search as search_text to leverage $text index in mongo
        if (search && search !== '') url = `${url}&search_text=${search}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        url = `${url}&only_relevant_fields=${false}`;
        // TODO fix!
        const payload: IResearchAPIPostPayloadWOrder = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
            sort_by: sortBy,
            sort_order: sortOrder,
        };
        return axios.post(url, payload, authHeaders(token));
    },
    async analyticsSpendMeta(
        token: string,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true,
        harmonize = false
    ) {
        let url = `${apiUrl}/api/v1/transactions/meta/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async schemaDistinctElements(
        token: string,
        collection: string,
        field: string,
        search: string = '',
        harmonize = false,
        skip = 0,
        limit = 20,
        add_empty_first: boolean = true,
        add_placeholder_first: boolean = false,
    ) {
        let url = `${apiUrl}/api/v1/schema/distinct/${collection}/${field}?skip=${skip}&limit=${limit}&harmonize=${harmonize}&add_empty_first=${add_empty_first}&add_placeholder_first=${add_placeholder_first}`;
        if (search && search !== '') url = `${url}&search=${search}`;
        return axios.get<ISchemaDistinctElement[]>(url, authHeaders(token));
    },
    async fuzzySearchReferenced(
        token: string,
        field: string,
        collection: string,
        searchString: string,
        harmonize = false,
    ) {
        let response = await this.schemaDistinctElements(
            token,
            collection,
            field,
            searchString,
            harmonize,
        );
        // Move id to _id and remove id for backward compatibility with the old method TODO
        if (response && response.data) {
            response.data = response.data.map((obj: any) => {
                obj["_id"] = obj["id"];
                delete obj["id"];
                return obj;
            });
        }
        return response;
    },
    async fuzzySearchAttribute(
        token: string,
        field: string,
        collection: string,
        searchString: string,
    ) {
        let response = await this.schemaDistinctElements(
            token,
            collection,
            field,
            searchString,
        );
        // Return only a list of names obj["name"] for backward compatibility with the old method TODO
        // Update the repsonse directly and return it
        if (response && response.data) {
            response.data = response.data.map((obj: any) => obj["name"]);
        }
        return response;
    },
    async distinctReferenced(
        token: string,
        field: string,
        collection: string,
        skip = 0,
        limit = 30,
        harmonize = false,
        add_empty_first: boolean = true,
        add_placeholder_first: boolean = false,
    ) {
        let response = await this.schemaDistinctElements(
            token,
            collection,
            field,
            '',
            harmonize,
            skip,
            limit,
            add_empty_first,
            add_placeholder_first,
        );
        // Move id to _id and remove id for backward compatibility with the old method TODO
        if (response && response.data) {
            response.data = response.data.map((obj: any) => {
                obj["_id"] = obj["id"];
                delete obj["id"];
                return obj;
            });
        }
        return response;
    },
    async distinctAttribute(
        token: string,
        field: string,
        collection: string,
        skip = 0,
        limit = 30,
    ) {
        let response = await this.schemaDistinctElements(
            token,
            collection,
            field,
            '',
            false,
            skip,
            limit,
        );
        // Return only a list of names obj["name"] for backward compatibility with the old method TODO
        if (response && response.data) {
            response.data = response.data.map((obj: any) => obj["name"]);
        }
        return response;
    },
    async analyticsSpendCount(
        token: string,
        attribute: string,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true,
        harmonize = false
    ) {
        let url = `${apiUrl}/api/v1/transactions/count/${attribute}?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async analyticsSpendPerTime(
        token: string,
        timeRange: string,
        startDate: string,
        endDate: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true,
        harmonize = false
    ) {
        let url = `${apiUrl}/api/v1/transactions/time/?`;
        url = `${url}time_range_type=${timeRange}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<IResearchSpendPerTime>(url, payload, authHeaders(token)); 
    },
    async analyticsSpendPerAttribute(
        token: string,
        attribute: string,
        startDate: string,
        endDate: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        secondaryCountField?: string,
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true,
        harmonize = false,
        furtherShareFields: string[] = [],
        skip = 0, limit = 0,
        sortBy: string[] = ["spend"],
        sortOrder: number[] = [-1],

    ) {
        let url = `${apiUrl}/api/v1/transactions/share/${attribute}?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        if (secondaryCountField && secondaryCountField !== '') url = `${url}&secondary_count_field=${secondaryCountField}`;
        if (furtherShareFields && furtherShareFields.length > 0) url = url + getFurtherFieldsParams(furtherShareFields);
        url = `${url}&skip=${skip}`;
        url = `${url}&limit=${limit}`;

        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
            sort_by: sortBy,
            sort_order: sortOrder,

        }

        const response = await axios.post(url, payload, authHeaders(token));

        // add random _id to objects, if no unique identifier is provided by API
        if (response.data) {
            if (response.data.length > 0) {
                if (!response.data[0]['_id'] || response.data[0]['_id'] == null) {
                    response.data = spendAttributeAddRandomID(response.data);
                }
            }
        }
        return response;
    },
    async analyticsSpendPerCategory(
        token: string,
        startDate: string,
        endDate: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        secondaryCountField?: string,
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true,
        harmonize = false,
        sortBy: string[] = ["spend"],
        sortOrder: number[] = [-1],
    ) {
        let url = `${apiUrl}/api/v1/transactions/share/category_id/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        if (secondaryCountField && secondaryCountField !== '') url = `${url}&secondary_count_field=${secondaryCountField}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
            sort_by: sortBy,
            sort_order: sortOrder,
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async analyticsWordCloud(
        token: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true
    ) {
        let url = `${apiUrl}/api/v1/transactions/wordcloud/?`;
        // TODO check for wordcloud API params
        url = `${url}include_childrens=${includeChildrens}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async analyticsPivot(
        token: string,
        startDate: string, endDate: string,
        fields: string[],
        timeRange: string,
        categoryHierarchy?: number,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        includeChildrens = true,
        harmonize = false,
        sortByTransaction = false
    ) {
        let url = `${apiUrl}/api/v1/transactions/pivot/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        if (fields && fields.length > 0) url = url + getFieldsParams(fields);
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&include_childrens=${includeChildrens}`;
        url = `${url}&harmonize=${harmonize}`;
        url = `${url}&sort_by_transaction=${sortByTransaction}`;
        if (timeRange && timeRange !== '') url = `${url}&time_range_type=${timeRange}`;
        if (categoryHierarchy && categoryHierarchy !== 0) url = `${url}&category_hierarchy=${categoryHierarchy}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    // methods for vendor analytics
    async vendorReadAll(token: string, skip = 0, limit = 500) {
        return axios.get(`${apiUrl}/api/v1/vendors/?skip=${skip}&limit=${limit}`, authHeaders(token));
    },
    async vendorhGetByID(token: string, vendorID: string) {
        return axios.get(`${apiUrl}/api/v1/vendors/${vendorID}`, authHeaders(token));
    },
    async vendorGetComments(token: string, vendorID: string) {
        return axios.get(`${apiUrl}/api/v1/vendors/${vendorID}/comments/`, authHeaders(token));
    },
    async vendorCreateComment(token: string, vendorID: string, payload: object) {
        return axios.post(`${apiUrl}/api/v1/vendors/${vendorID}/comments`, payload, authHeaders(token));
    },
    async vendorhDeleteComment(token: string, vendorID: string, commentID: string) {
        return axios.delete(`${apiUrl}/api/v1/vendors/${vendorID}/comments/${commentID}`, authHeaders(token));
    },
    async vendorUpdateComment(token: string, vendorID: string, commentID: string, payload: object) {
        return axios.put(`${apiUrl}/api/v1/vendors/${vendorID}/comments/${commentID}`, payload, authHeaders(token));
    },
    // methods for analytics KPI
    async kpiReadAll(token: string, filterFields?: string[], filterValues?: string[][]) {
        const url = `${apiUrl}/api/v1/kpis/`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post<IKPIMeta[]>(url, payload, authHeaders(token));
    },
    async kpiReadMeta(
        token: string,
        filterFields?: string[],
        filterValues?: string[][],
    ) {
        const url = `${apiUrl}/api/v1/kpis/meta/`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        }
        return axios.post(url, payload, authHeaders(token));
    },
    async kpiHasFact(
        token: string,
        identifier: string,
        startDate?: string,
        endDate?: string,
    ) {
        let url = `${apiUrl}/api/v1/kpis/identifier/${identifier}/hasfact/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;

        return axios.get(url, authHeaders(token));
    },
    async kpiCalculate(
        token: string,
        identifier: string,
        field?: string,
        time_range_type?: IKPIAvailableTimeRanges,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        additional_field?: string,
        additional_value?: string,
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
        limit = 100,
    ) {
        let url = `${apiUrl}/api/v1/kpis/calculate/${identifier}?`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&harmonize=${harmonize}`;
        url = `${url}&limit=${limit}`;

        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        if (field && field !== undefined) url = `${url}&field=${field}`;
        if (time_range_type && time_range_type !== undefined) url = `${url}&time_range_type=${time_range_type}`;
        if (additional_field && additional_value !== undefined) url = `${url}&additional_field=${additional_field}&additional_value=${encodeURIComponent(additional_value)}`;

        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }

        return axios.post(url, payload, authHeaders(token));
    },
    async kpiInsight(
        token: string,
        identifier: string,
        timeRange: IKPIAvailableTimeRanges,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
    ) {
        let url = `${apiUrl}/api/v1/kpis/insight/${identifier}?time_range_type=${timeRange}`;
        url = `${url}&${getCategoryShowTypeURLParams(categoryType)}`;
        url = `${url}&harmonize=${harmonize}`;

        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;

        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }

        return axios.post<IKPIInsight>(url, payload, authHeaders(token));
    },
    async kpiInsightMeta(
        token: string,
        timeRange: IKPIAvailableTimeRanges,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        categoryType: IResearchShowCategoryTypes = 'TRANSACTION_BASED',
        harmonize = false,
    ) {
        let url = `${apiUrl}/api/v1/kpis/insight/meta/?time_range_type=${timeRange}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<IKPIInsightMeta>(url, payload, authHeaders(token));
    },
    // methods for KPI targets
    async kpiTargetRead(
        token: string,
        identifier: string,
        timeRange?: IKPIAvailableTimeRanges,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][]
    ) {
        let url = `${apiUrl}/api/v1/kpi_targets/filter/${identifier}?`;
        if (timeRange) url = `${url}time_range_type=${timeRange}&`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<IKPITarget[]>(url, payload, authHeaders(token));
    },
    async kpiTargetReadMeta(
        token: string,
        identifier: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][]
    ) {
        const url = `${apiUrl}/api/v1/kpi_targets/meta/?identifier=${identifier}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<number>(url, payload, authHeaders(token));
    },
    async kpiTargetCreate(token: string, target: IKPITargetCreateUpdate) {
        const url = `${apiUrl}/api/v1/kpi_targets/`;
        return axios.post<IKPITarget>(url, target, authHeaders(token));
    },
    async kpiTargetUpdate(token: string, targetID: string, target: IKPITargetCreateUpdate) {
        const url = `${apiUrl}/api/v1/kpi_targets/${targetID}`;
        return axios.put<IKPITarget>(url, target, authHeaders(token));
    },
    async kpiTargetDelete(token: string, targetID: string) {
        const url = `${apiUrl}/api/v1/kpi_targets/${targetID}`;
        return axios.delete<string>(url, authHeaders(token));
    },
    // methods for KPI benchmarks
    async kpiBenchmarkRead(
        token: string,
        identifier: string,
        timeRange?: IKPIAvailableTimeRanges, startDate?: string, endDate?: string,
        filterFields?: string[], filterValues?: string[][],
        filterFieldsNE?: string[], filterValuesNE?: string[][]
    ) {
        let url = `${apiUrl}/api/v1/kpi_benchmarks/filter/${identifier}?`;
        if (timeRange) url = `${url}time_range_type=${timeRange}&`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<IKPIBenchmark[]>(url, payload, authHeaders(token));
    }, 
    // methods for company facts
    async factsReadTypes(token: string) {
        const url = `${apiUrl}/api/v1/facts/types/`;
        return axios.post<[string[], IKPIValueTypes[]]>(url, [], authHeaders(token));
    },
    async factsRead(token: string, filterFields?: string[], filterValues?: string[][], filterFieldsNE?: string[], filterValuesNE?: string[][],) {
        const url = `${apiUrl}/api/v1/facts/filter/`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<IFact[]>(url, payload, authHeaders(token));
    },
    async factsReadMeta(token: string, filterFields?: string[], filterValues?: string[][], filterFieldsNE?: string[], filterValuesNE?: string[][],) {
        const url = `${apiUrl}/api/v1/facts/meta/`;
        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        }
        return axios.post<number>(url, payload, authHeaders(token));
    },
    async factsCreate(token: string, fact: IFactCreateUpdate) {
        const url = `${apiUrl}/api/v1/facts/`;
        return axios.post<IFact>(url, fact, authHeaders(token));
    },
    async factsUpdate(token: string, factID: string, fact: IFactCreateUpdate) {
        const url = `${apiUrl}/api/v1/facts/${factID}`;
        return axios.put<IFact>(url, fact, authHeaders(token));
    },
    async factsDelete(token: string, factID: string) {
        const url = `${apiUrl}/api/v1/facts/${factID}`;
        return axios.delete<string>(url, authHeaders(token));
    },
    // methods for category collection
    async categoryCollectionReadMulti(token: string, skip = 0, limit = 100, search?: string) {
        let url = `${apiUrl}/api/v1/categories/?skip=${skip}&limit=${limit}&search=${search}`;
        return axios.get<IResearchCategoryData[]>(url, authHeaders(token));
    },
    async categoryCollectionReadByID(token: string, categoryID: string) {
        return axios.get(`${apiUrl}/api/v1/categories/${categoryID}/`, authHeaders(token));
    },
    async categoryCollectionReadByIDs(token: string, categoryIDs: string[]) {
        let categoryString = '';
        let iCategory = 0;
        for (const id of categoryIDs) {
            if (iCategory === 0) {
                categoryString = `${id}`;
            } else {
                categoryString = `${categoryString}&category_ids=${id}`;
            }
            iCategory++;
        }
        return axios.get(`${apiUrl}/api/v1/categories/ids/?category_ids=${categoryString}`, authHeaders(token));
    },
    async categoryCollectionReadByIdentifier(token: string, identifier: string) {
        return axios.get(`${apiUrl}/api/v1/categories/identifier/${identifier}/`, authHeaders(token));
    },
    async categoryCollectionReadCollection(token: string, identifierPath: string[][], hierarchy = 1) {
        return axios.post(`${apiUrl}/api/v1/categories/collection/?hierarchy=${hierarchy}`, identifierPath, authHeaders(token));
    },
    async categoryCollectionReadChildsAll(token: string, categoryID: string) {
        return axios.get(`${apiUrl}/api/v1/categories/${categoryID}/childs/all/`, authHeaders(token));
    },
    async categoryCollectionReadChildsDirect(token: string, categoryID: string) {
        return axios.get(`${apiUrl}/api/v1/categories/${categoryID}/childs/direct/`, authHeaders(token));
    },
    async categoryCollectionReadPath(token: string, payload: string[]) {
        return axios.post(`${apiUrl}/api/v1/categories/path/`, payload, authHeaders(token));
    },
    async categoryCollectionMaxHierarchy(token: string) {
        return axios.get(`${apiUrl}/api/v1/categories/maxhierarchy/`, authHeaders(token));
    },
    async categoryCollectionIsLeaf(token: string, categoryID: string) {
        return axios.get(`${apiUrl}/api/v1/categories/leaf/${categoryID}`, authHeaders(token));
    },

    // methods for action log
    async actionsProjects(
        token: string,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        skip: number = 0,
        limit: number = -1,
    ) {
        let url = `${apiUrl}/api/v1/projects/filter/?skip=${skip}&limit=${limit}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        const payload: IResearchAPIPostPayloadWOrder = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
            sort_by: ['created_at'],
            sort_order: [-1],
        };
        return axios.post<IActionProject[]>(url, payload, authHeaders(token));
    },
    async actionsProjectsMeta(
        token: string,
        filterFields?: string[],
        filterValues?: string[][],
    ) {
        let url = `${apiUrl}/api/v1/projects/meta/`;
        const payload: IResearchAPIPostPayloadWOrder = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
        };
        return axios.post<IActionProjectsMetaResponse>(url, payload, authHeaders(token));
    },
    async actionsCreateProject(
        token: string,
        project: IActionProjectCreate,
    ) {
        let url = `${apiUrl}/api/v1/projects/?`;
        return axios.post<IActionProject>(url, project, authHeaders(token));
    },
    async actionsDeleteProject(
        token: string,
        projectID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}`;
        return axios.delete(url, authHeaders(token));
    },
    async actionsEditProject(
        token: string,
        projectID: string,
        project: IActionProjectCreate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}`;
        return axios.put<IActionProject>(url, project, authHeaders(token));
    },
    async actionsEditProjectLifecycle(
        token: string,
        projectID: string,
        lifecyle: IActionsLifecycles,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/status/?status=${lifecyle}`;
        return axios.put<IActionProject>(url, undefined, authHeaders(token));
    },
    async actionsCreateTask(
        token: string,
        projectID: string,
        task: IActionTaskCreateUpdate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/task/`;
        return axios.post<IActionTask>(url, task, authHeaders(token));
    },
    async actionsReorderTasks(
        token: string,
        projectID: string,
        idOrderArray: string[],
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/task/`;
        return axios.put<IActionProject>(url, idOrderArray, authHeaders(token));
    },
    async actionsDeleteTask(
        token: string,
        projectID: string,
        taskID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/task/${taskID}`;
        return axios.delete(url, authHeaders(token));
    },
    async actionsEditTask(
        token: string,
        projectID: string,
        taskID: string,
        task: IActionTask,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/task/${taskID}`;
        return axios.put<IActionTask>(url, task, authHeaders(token));
    },

    async actionsCreateComment(
        token: string,
        projectID: string,
        comment: IActionCommentCreateEdit,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/comment/`;
        return axios.post<IActionComment>(url, comment, authHeaders(token));
    },
    async actionsDeleteComment(
        token: string,
        projectID: string,
        commentID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/comment/${commentID}`;
        return axios.delete(url, authHeaders(token));
    },
    async actionsEditComment(
        token: string,
        projectID: string,
        commentID: string,
        comment: IActionComment,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/comment/${commentID}`;
        return axios.put<IActionCommentCreateEdit>(url, comment, authHeaders(token));
    },

    // approval flows
    async actionFindValidApprovalFlows(
        token: string,
        projectID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/approval_flow/find/`;
        return axios.get<IActionValidApprovalFlows[]>(url, authHeaders(token));
    },
    async actionsCreateApprovalFlow(
        token: string,
        projectID: string,
        flowCreate: IActionApprovalFlowCreate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/approval_flow/`;
        return axios.post<IActionProject>(url, flowCreate, authHeaders(token));
    },
    async actionsStartApprovalFlow(
        token: string,
        projectID: string,
        restart: boolean = false,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/approval_flow/start/?restart=${restart}`;
        return axios.get<IActionProject>(url, authHeaders(token));
    },
    async actionsApproveApprovalFlow(
        token: string,
        projectID: string,
        deny: boolean = false,
        denyComment?: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/approval_flow/approve/?deny=${deny}`;
        if (denyComment) url = `${url}&deny_comment=${denyComment}`; 
        return axios.get<IActionProject>(url, authHeaders(token));
    },

    // attachments
    async actionsCreateAttachment(
        token: string,
        projectID: string,
        file: File,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/attachment/`;

        const formData = new FormData();
        formData.append('file', file);
        const fileHeaders: AxiosRequestConfig = authHeaders(token);
        fileHeaders.headers = fileHeaders.headers || {};
        fileHeaders['headers']['Content-Type'] = 'multipart/form-data';

        return axios.post(url, formData, fileHeaders);
    },
    async actionsDeleteAttachment(
        token: string,
        projectID: string,
        attachmentID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/attachment/${attachmentID}`;
        return axios.delete(url, authHeaders(token));
    },
    async actionsReadAttachment(
        token: string,
        projectID: string,
        attachmentID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/attachment/${attachmentID}`;
        return axios.get(url,
            {
                ...authHeaders(token),
                responseType: 'blob',
            }
        );
    },
    // CRUD Savings Positions
    async actionsCreateSavingsPosition(
        token: string,
        projectID: string,
        savingsPosition: IActionsSavingsPositionCreateUpdate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/savings_position/`;
        return axios.post<IActionProject>(url, savingsPosition, authHeaders(token));
    },
    async actionsDeleteSavingsPosition(
        token: string,
        projectID: string,
        savingsPositionID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/savings_position/${savingsPositionID}`;
        return axios.delete(url, authHeaders(token));
    },
    async actionsEditSavingsPosition(
        token: string,
        projectID: string,
        savingsPositionID: string,
        savingsPosition: IActionsSavingsPositionCreateUpdate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/savings_position/${savingsPositionID}`;
        return axios.put<IActionProject>(url, savingsPosition, authHeaders(token));
    },
    // CRUD non financial benefits
    async actionsCreateBenefitsPosition(
        token: string,
        projectID: string,
        benefitsPosition: IActionsNonFinancialBenefitsPositionCreateUpdate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/benefits_position/`;
        return axios.post<IActionProject>(url, benefitsPosition, authHeaders(token));
    },
    async actionsDeleteBenefitsPosition(
        token: string,
        projectID: string,
        benefitsPositionID: string,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/benefits_position/${benefitsPositionID}`;
        return axios.delete(url, authHeaders(token));
    },
    async actionsEditBenefitsPosition(
        token: string,
        projectID: string,
        benefitsPositionID: string,
        benefitsPosition: IActionsNonFinancialBenefitsPositionCreateUpdate,
    ) {
        let url = `${apiUrl}/api/v1/projects/${projectID}/benefits_position/${benefitsPositionID}`;
        return axios.put<IActionProject>(url, benefitsPosition, authHeaders(token));
    },
    async actionsReadBenefitsPositions(
        token: string,
        projectID: string,
        filterFields: object[] = [],
        filterValues: object[] = [],
    ) {
        let url = `${apiUrl}/api/v1/project_benefits_positions/filter/`;
        const payload = {
            filter_fields: filterFields,
            filter_values: filterValues,
            sort_by: ['created_at'],
            sort_order: [-1],
        };
        if (projectID) {
            payload['params_for_projects'] = {
                filter_fields: ["_id"],
                filter_values: [[projectID]],
            }
        }
        /*if (params_for_projects) {
            payload['params_for_projects'] = params_for_projects;
        }*/
        return axios.post<IActionsNonFinancialBenefitsPositionCreateUpdate[]>(url, payload, authHeaders(token));
    },

    // Savings positions / savings dashboard
    async savingsPositions(
        token: string,
        period_accrual: boolean,
        showAll?: boolean,
        userProfile?: IUserProfile | null,
        projectID?: string,
        params_for_projects?: object,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        limit: number = -1,
    ) {
        let url = `${apiUrl}/api/v1/project_savings_positions/filter/?`;
        url = `${url}period_accrual=${period_accrual}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&limit=${limit}`;
        // let filters = savingsUserRoleManagement(showAll, userProfile, (filterFields && filterFields.length > 0) ? filterFields : [], (filterValues && filterValues.length > 0) ? filterValues : []);
        const payload = {
            filter_fields: filterFields,
            filter_values: filterValues,
            sort_by: ['created_at'],
            sort_order: [-1],
        };
        if (projectID) {
            payload['params_for_projects'] = {
                filter_fields: ["_id"],
                filter_values: [[projectID]],
            }
        }
        if (params_for_projects) {
            payload['params_for_projects'] = params_for_projects;
        }
        return axios.post<IActionsSavingsPositionCreateUpdate[]>(url, payload, authHeaders(token));
    },
    async savingsPositionsMeta(
        token: string,
        period_accrual: boolean,
        showAll: boolean,
        userProfile: IUserProfile | null,
        paramsForProjects?: object,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
    ) {
        let url = `${apiUrl}/api/v1/project_savings_positions/meta/?`;
        url = `${url}period_accrual=${period_accrual}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') {
            url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        }
        // let filters = savingsUserRoleManagement(showAll, userProfile, (filterFields && filterFields.length > 0) ? filterFields : [], (filterValues && filterValues.length > 0) ? filterValues : []);
        const payload = {
            filter_fields: filterFields,
            filter_values: filterValues,
        };
        if (paramsForProjects) {
            payload['params_for_projects'] = paramsForProjects;
        }
        return axios.post<ISavingsPositionMeta>(url, payload, authHeaders(token));
    },
    async savingsPositionsFieldsShare(
        token: string,
        field: string,
        period_accrual: boolean,
        showAll: boolean,
        userProfile: IUserProfile | null,
        paramsForProjects?: object,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
    ) {
        let url = `${apiUrl}/api/v1/project_savings_positions/share/${field}?`;
        url = `${url}&period_accrual=${period_accrual}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        let filters = savingsUserRoleManagement(showAll, userProfile, (filterFields && filterFields.length > 0) ? filterFields : [], (filterValues && filterValues.length > 0) ? filterValues : []);
        const payload = {
            filter_fields: filterFields,
            filter_values: filterValues,
        };
        if (paramsForProjects) {
            payload['params_for_projects'] = paramsForProjects;
        }
        return axios.post<ISavingsPositionField[]>(url, payload, authHeaders(token));
    },
    async savingsPositionsTime(
        token: string,
        timeRange: string,
        period_accrual: boolean,
        showAll: boolean,
        userProfile: IUserProfile | null,
        paramsForProjects?: object,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
    ) {
        let url = `${apiUrl}/api/v1/project_savings_positions/time/?`;
        url = `${url}time_range_type=${timeRange}`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&period_accrual=${period_accrual}`;
        let filters = savingsUserRoleManagement(showAll, userProfile, (filterFields && filterFields.length > 0) ? filterFields : [], (filterValues && filterValues.length > 0) ? filterValues : []);
        const payload: IResearchAPIPostPayload = {
            filter_fields: filters.fields,
            filter_values: filters.values,
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        };
        if (paramsForProjects) {
            payload.params_for_projects = paramsForProjects;
        }
        return axios.post<ISavingsPositionSavings[]>(url, payload, authHeaders(token));
    },
    async savingsCategory(
        token: string,
        period_accrual: boolean,
        showAll: boolean,
        userProfile: IUserProfile | null,
        paramsForProjects?: object,
        startDate?: string,
        endDate?: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
    ) {
        let url = `${apiUrl}/api/v1/project_savings_positions/share/category_id/?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        url = `${url}&period_accrual=${period_accrual}`;
        let filters = savingsUserRoleManagement(showAll, userProfile, (filterFields && filterFields.length > 0) ? filterFields : [], (filterValues && filterValues.length > 0) ? filterValues : []);
        const payload: IResearchAPIPostPayload = {
            filter_fields: filters.fields,
            filter_values: filters.values,
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
        };
        if (paramsForProjects) {
            payload.params_for_projects = paramsForProjects;
        }
        return axios.post<ISavingsPositionShareCategory[]>(url, payload, authHeaders(token));
    },
    async savingsFields(
        token: string,
    ) {
        const url = `${apiUrl}/api/v1/project_savings_positions/fields/`;
        return axios.get<string[]>(url, authHeaders(token));
    },
    // methods for spend insights
    async insightsTypes(
        token: string,
    ) {
        let url = `${apiUrl}/api/v1/insights/types/`;
        return axios.post<string[]>(url, [], authHeaders(token));
    },
    async insightsCalculate(
        token: string,
        identifier: string,
        startDate: string,
        endDate: string,
        filterFields?: string[],
        filterValues?: string[][],
        filterFieldsNE?: string[],
        filterValuesNE?: string[][],
        skip = 0, limit = 0,
        sortBy: string[] = ["spend"],
        sortOrder: number[] = [-1],
    ) {
        let url = `${apiUrl}/api/v1/insights/calculate/${identifier}?`;
        if (startDate && startDate !== '' && endDate && endDate !== '') url = `${url}start_date=${startDate}&end_date=${endDate}`;
        // url = `${url}&skip=${skip}`;
        // url = `${url}&limit=${limit}`;

        const payload: IResearchAPIPostPayload = {
            filter_fields: (filterFields && filterFields.length > 0) ? filterFields : [],
            filter_values: (filterValues && filterValues.length > 0) ? filterValues : [],
            ne_filter_fields: (filterFieldsNE && filterFieldsNE.length > 0) ? filterFieldsNE : [],
            ne_filter_values: (filterValuesNE && filterValuesNE.length > 0) ? filterValuesNE : [],
            // sort_by: sortBy,
            // sort_order: sortOrder,

        }
        return axios.post<IInsightsResult[]>(url, payload, authHeaders(token));
    },
    // methods for model predictions
    async getPrediction(token: string, payload: object) {
        // TODO create interface for prediction results
        return axios.post(`${apiUrl}/api/v1/models/predict`, payload, authHeaders(token));
    },
    // methods for view settings
    async viewsReadViews(token: string) {
        // TODO always send emtpy payload for now, since there is no filtered view handling yet
        return axios.post<IView[]>(`${apiUrl}/api/v1/views/filter/`, {}, authHeaders(token));
    },
    async viewsCreateView(token: string, view: IViewCreate) {
        return axios.post<IView>(`${apiUrl}/api/v1/views/`, view, authHeaders(token));
    },
    async viewsDeleteView(token: string, view_id: string) {
        return axios.delete(`${apiUrl}/api/v1/views/${view_id}`, authHeaders(token));
    },
    async viewsMeta(token: string) {
        // TODO always send emtpy payload for now, since there is no filtered view handling yet
        return axios.post(`${apiUrl}/api/v1/views/meta/`, {}, authHeaders(token));
    },
    // methods for settings
    async transactionsPopulatedFields(token: string) {
        return axios.get<string[]>(`${apiUrl}/api/v1/transactions/fields/`, authHeaders(token));
    },
    async categorizerReadSchema(token: string) { // TODO delete
        return axios.get<ICategorylabSchema[]>(`${apiUrl}/api/v1/settings/categorizer/schema/`, authHeaders(token));
    }, 
    async categorylabReadSchema(token: string) {
        return axios.get<ICategorylabSchema[]>(`${apiUrl}/api/v1/settings/categorizer/schema/`, authHeaders(token));
    }, 
    async categorizerReadSchemaAll(token: string) { // TODO delete
        return axios.get<ICategorylabSchema[]>(`${apiUrl}/api/v1/settings/categorizer/schema/all/`, authHeaders(token));
    },
    async categorylabReadSchemaAll(token: string) {
        return axios.get<ICategorylabSchema[]>(`${apiUrl}/api/v1/settings/categorizer/schema/all/`, authHeaders(token));
    },
    async categorylabUpdateSchema(token: string, payload: ICategorylabSchema[]) {
        return axios.put(`${apiUrl}/api/v1/settings/categorizer/schema/`, payload, authHeaders(token));
    },
    async clusterReadSchema(token: string) {
        return axios.get<IClusterSchema[]>(`${apiUrl}/api/v1/settings/cluster/schema/`, authHeaders(token));
    },
    async clusterReadSchemaAll(token: string) {
        return axios.get<IClusterSchema[]>(`${apiUrl}/api/v1/settings/cluster/schema/all/`, authHeaders(token));
    },
    async clusterUpdateSchema(token: string, payload: IClusterSchema[]) {
        return axios.put(`${apiUrl}/api/v1/settings/cluster/schema/`, payload, authHeaders(token));
    },
    async aggregationReadSchema(token: string) {
        return axios.get<IAggregationSchema[]>(`${apiUrl}/api/v1/settings/aggregation/schema/`, authHeaders(token));
    },
    async aggregationReadSchemaAll(token: string) {
        return axios.get<IAggregationSchema[]>(`${apiUrl}/api/v1/settings/aggregation/schema/all/`, authHeaders(token));
    },
    async aggregationUpdateSchema(token: string, payload: IAggregationSchema[]) {
        return axios.put(`${apiUrl}/api/v1/settings/aggregation/schema/`, payload, authHeaders(token));
    },
    async languagesReadAll(token: string) {
        return axios.get<string[]>(`${apiUrl}/api/v1/settings/languages/all/`, authHeaders(token));
    },
    async languagesReadCurrent(token: string) {
        return axios.get<string[]>(`${apiUrl}/api/v1/settings/languages/`, authHeaders(token));
    },
    async languagesUpdate(token: string, payload: string[]) {
        return axios.put(`${apiUrl}/api/v1/settings/languages/`, payload, authHeaders(token));
    },
    // methods for oss
    async ossDataList(token: string) {
        return axios.get<object[]>(`${apiUrl}/api/v1/files/oss/list/`, authHeaders(token));
    },
    async ossDataFileExists(token: string, fileName: string) {
        return axios.get<boolean>(`${apiUrl}/api/v1/files/oss/exist/${fileName}`, authHeaders(token));
    },
    async ossDataFileFetch(token: string, fileName: string) {
        return axios.get(`${apiUrl}/api/v1/files/oss/fetch/${fileName}`, authHeadersBlob(token));
    },
    // methods for etl process
    async getETL(token: string) {
        return axios.post<IETLSchema[]>(`${apiUrl}/api/v1/etl/`, {}, authHeaders(token));
    },
    async updateETL(token: string, etlId: string, etl: IETLSchema) {
        return axios.put(`${apiUrl}/api/v1/etl/${etlId}`, etl, authHeaders(token));
    },
    // methods for sales case
    async salesCaseReadAll(token: string) {
        return axios.get<ISalesCaseCompany[]>(`${apiUrl}/api/v1/sales_case/`, authHeaders(token));
    },
    async salesCaseReadByNameAndToken(token: string, companyName: string, companyToken: string) {
        return axios.get<ISalesCaseCompany>(`${apiUrl}/api/v1/sales_case/name/${companyName}/${companyToken}`, authHeaders(token));
    },
    async salesCaseReadByToken(token: string, companyToken: string) {
        return axios.get<ISalesCaseCompany>(`${apiUrl}/api/v1/sales_case/token/${companyToken}`, authHeaders(token));
    },
    async salesCaseCreate(token: string, payload: ISalesCaseCompanyCreateEdit) {
        return axios.post(`${apiUrl}/api/v1/sales_case/`, payload, authHeaders(token));
    },
    async salesCaseEdit(token: string, salesCaseID: string, payload: ISalesCaseCompanyCreateEdit) {
        return axios.put(`${apiUrl}/api/v1/sales_case/${salesCaseID}`, payload, authHeaders(token));
    },
    async salesCaseDelete(token: string, salesCaseID: string) {
        return axios.delete(`${apiUrl}/api/v1/sales_case/${salesCaseID}`, authHeaders(token));
    },

};
