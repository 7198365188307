import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { UserRoleEnum, PlanFeatureEnum } from '@/interfaces';

export const getters = {
    // CHECK ROLES
    hasConsumerRole: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active && state.userProfile.role === UserRoleEnum.CONSUMER);
    },
    hasPurchaserRole: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active && state.userProfile.role === UserRoleEnum.PURCHASER);
    },
    hasManagementRole: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active && state.userProfile.role === UserRoleEnum.MANAGEMENT);
    },
    hasAdminRole: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active && state.userProfile.role === UserRoleEnum.ADMIN);
    },
    hasSuperUserRole: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active && state.userProfile.is_superuser);
    },

    // CHECK ACCESS
    // TODO Fetch from backend dynamically
    hasResearchAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.RESEARCH) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.RESEARCH)));
    },
    hasClusterAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.CLUSTER) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.CLUSTER)));
    },
    hasCategorizerAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.CATEGORIZER) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.CATEGORIZER)));
    },
    hasCategorizerAggregatedAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.CATEGORIZER_AGGREGATED) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.CATEGORIZER_AGGREGATED)));
    },
    hasCategoryAPIAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.CATEGORY_API) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.CATEGORY_API)));
    },
    hasInsightsAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.INSIGHTS) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.INSIGHTS)));
    },
    hasKPIAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.KPI) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.KPI)));
    },
    hasActionsAccess: (state: MainState) => {
        // TODO Change to actions
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.ACTION_LOG) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.ACTION_LOG)));
    },
    hasReportsAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.REPORTS) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.REPORTS)));
    },
    hasLLMAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_active &&
            (state.userProfile.features.includes(PlanFeatureEnum.LLM) || state.userProfile.organization.plan.features.includes(PlanFeatureEnum.LLM)));
    },

    //  LOGIN
    loginError: (state: MainState) => state.logInError,
    loginErrorMessage: (state: MainState) => state.logInErrorMessage,
    isLoggedIn: (state: MainState) => state.isLoggedIn,

    // USER AND ORGANIZATION
    userProfile: (state: MainState) => state.userProfile,
    userLang: (state: MainState) => state.userProfile?.options.lang,
    userKPIFavorites: (state: MainState) => state.userProfile?.options.kpi_favorites,
    organization: (state: MainState) => state.userProfile?.organization,
    organizationDBName: (state: MainState) => state.userProfile?.organization.db_name,
    organizationID: (state: MainState) => state.userProfile?.organization._id,
    organizationCategorizerSchema: (state: MainState) => state.userProfile?.organization.categorizer_schema,
    orgaUsers: (state: MainState) => state.orgaUserProfiles,

    // TASKS & PREDICTIONS
    predictionsLoading: (state: MainState) => state.predictionsLoading,
    token: (state: MainState) => state.token,

    // LUT
    lutLoading: (state: MainState) => state.lutLoading,

    // Docs
    showCheatSheet: (state: MainState) => state.showCheatSheet,
};

const { read } = getStoreAccessors<MainState, State>('');

export const readToken = read(getters.token);

export const readHasSuperUserRole = read(getters.hasSuperUserRole);
export const readHasAdminRole = read(getters.hasAdminRole);
export const readHasManagementRole = read(getters.hasManagementRole);
export const readHasPurchaserRole = read(getters.hasPurchaserRole);
export const readHasConsumerRole = read(getters.hasConsumerRole);

export const readHasResearchAccess = read(getters.hasResearchAccess);
export const readHasClusterAccess = read(getters.hasClusterAccess);
export const readHasCategorizerAggregatedAccess = read(getters.hasCategorizerAggregatedAccess);
export const readHasCategorizerAccess = read(getters.hasCategorizerAccess);
export const readHasCategoryAPIAccess = read(getters.hasCategoryAPIAccess);
export const readHasInsightsAccess = read(getters.hasInsightsAccess);
export const readHasKPIAccess = read(getters.hasKPIAccess);
export const readHasActionsAccess = read(getters.hasActionsAccess);
export const readHasReportsAccess = read(getters.hasReportsAccess);
export const readHasLLMAccess = read(getters.hasLLMAccess);

export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readLoginErrorMessage = read(getters.loginErrorMessage);

export const readUserProfile = read(getters.userProfile);
export const readUserLang = read(getters.userLang);
export const readUserKPIFavorites = read(getters.userKPIFavorites);
export const readOrganization = read(getters.organization);
export const readOrganizationDBName = read(getters.organizationDBName);
export const readOrganizationID = read(getters.organizationID);
export const readOrganizationCategorizerSchema = read(getters.organizationCategorizerSchema);
export const readOrganizationUsers = read(getters.orgaUsers);

export const readPredictionsLoading = read(getters.predictionsLoading);

export const readLUTLoading = read(getters.lutLoading);

export const readShowCheatSheet = read(getters.showCheatSheet);
