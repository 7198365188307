import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ICategoryLabState } from './state';

const defaultCategoryLabState: ICategoryLabState = {
    schema: [],
    availableSchema: [],
    schemaLoaded: false,
};

export const categoryLabModule = {
    name: 'categorylab',
    namespaced: false,
    state: defaultCategoryLabState,
    getters,
    mutations,
    actions,
};
