import { ICategoryLabState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    searchCategoryLabSchema: (state: ICategoryLabState) => state.schema,
    searchAvailableCategoryLabSchema: (state: ICategoryLabState) => state.availableSchema,
    searchCategoryLabSchemaLoaded: (state: ICategoryLabState) => state.schemaLoaded,
};

const {read} = getStoreAccessors<ICategoryLabState, State>('');

export const readCategoryLabSchema = read(getters.searchCategoryLabSchema);
export const readAvailableCategoryLabSchema = read(getters.searchAvailableCategoryLabSchema);
export const readCategoryLabSchemaLoaded = read(getters.searchCategoryLabSchemaLoaded);
