import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import i18n from './vue-i18n'
import config from '../configs';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: config.theme.isRTL,
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true,
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light,
    },
  },
  lang: {
    current: config.locales.locale,
    t: (key, ...params) => i18n.t(key, params) as string
  }
});
