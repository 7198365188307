import Vuetify from '../../plugins/vuetify';
import { AppState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  /**
   * Main Toast
   */
  showToast(state: AppState, toast) {
    const { color, timeout, message } = toast;
    state.toast = {
      message,
      color,
      timeout,
      show: true,
    };
  },
  hideToast(state: AppState) {
    state.toast.show = false;
  },
  resetToast(state: AppState) {
    state.toast = {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000,
    };
  },
  /**
   * Theme and Layout
   */
  setGlobalTheme(state: AppState, theme) {
    Vuetify.framework.theme.dark = theme === 'dark';
    state.globalTheme = theme;
  },
  setRTL(state: AppState, isRTL) {
    Vuetify.framework.rtl = isRTL;
    state.isRTL = isRTL;
  },
  setContentBoxed(state: AppState, isBoxed) {
    state.isContentBoxed = isBoxed;
  },
  setMenuTheme(state: AppState, theme) {
    state.menuTheme = theme;
  },
  setToolbarTheme(state: AppState, theme) {
    state.toolbarTheme = theme;
  },
  setToolbarDetached(state: AppState, isDetached) {
    state.isToolbarDetached = isDetached;
  },
  setToolbarMini(state: AppState, isMini) {
    state.isToolbarMini = isMini;
  },
  setCurrentCurrency(state: AppState, currency: object) {
    state.currency = currency;
  },
};

const {commit} = getStoreAccessors<AppState | any, State>('');

export const commitShowToast = commit(mutations.showToast);
export const commitHideToast = commit(mutations.hideToast);
export const commitResetToast = commit(mutations.resetToast);
export const commitSetGlobalTheme = commit(mutations.setGlobalTheme);
export const commitSetRTL = commit(mutations.setRTL);
export const commitSetContentBoxed = commit(mutations.setContentBoxed);
export const commitSetMenuTheme = commit(mutations.setMenuTheme);
export const commitSetToolbarTheme = commit(mutations.setToolbarTheme);
export const commitSetToolbarDetached = commit(mutations.setToolbarDetached);
export const commitSetToolbarMini = commit(mutations.setToolbarMini);
export const commitSetCurrentCurrency = commit(mutations.setCurrentCurrency);
