import { IInsightsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IActionProject } from '@/interfaces/actions';
import { IInsightsData } from '@/interfaces/insights';


export const mutations = {
    setInsights(state: IInsightsState, payload: IInsightsData[]) {
        state.insightsData = payload;
    },
};

const {commit} = getStoreAccessors<IInsightsState | any, State>('');

export const commitSetInsightsData = commit(mutations.setInsights);

