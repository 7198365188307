import { IDataManagementFieldValueTypes } from '@/interfaces/dataManagement'
import moment from 'moment'
import Vue from 'vue'
import store from '../store'

Vue.filter('formatCurrency', formatCurrency)
Vue.filter('formatNumber', formatNumber)
Vue.filter('formatPercentage', formatNumber)
Vue.filter('formatValue', formatValue)

/**
 * Returns the currency formatted accordingly
 */
export function formatCurrency(value, currency?, decimals?: number) {
  currency = currency || store.state.app.currency;
  const currUse = {...currency};
  if (decimals) currUse.decimalDigits = decimals
  return formatPrice(value, currUse);
}

/**
 * Returns the number formatted accordingly
 */
 export function formatNumber(value, currency?, decimals?: number) {
  currency = currency || store.state.app.currency;
  const currUse = {...currency};
  if (decimals) currUse.decimalDigits = decimals;
  return formatNumberHelper(value, currUse);
}

/**
 * Returns the a percentage formatted accordingly
 */
 export function formatPercentage(value, currency?, decimals?: number) {
  currency = currency || store.state.app.currency;
  const currUse = {...currency};
  if (decimals) currUse.decimalDigits = decimals;
  return formatPercentageHelper(value, currUse);
}

/**
 * Formats value types from routes correctly
 */
export function formatValue(value, valueType: IDataManagementFieldValueTypes, valueYes = 'Ja', valueNo = 'Nein') {
  if (valueType == 'TEXT') {
    return value;
  } else if (valueType == 'NUMBER') {
    return formatNumber(value, undefined, 2);
  } else if (valueType == 'CURRENCY_VALUE') {
    return formatCurrency(value);
  } else if (valueType == 'TIMESTAMP') {
      return moment(value).format("DD.MM.YYYY");
  } else if (valueType == 'BOOLEAN') {
    return (value == true || value == 'True') ? valueYes : valueNo;
  }
}

/**
* Returns the price passed formatted according to the system configurations for the currency
*
* @param price
* @returns {*}
*/
export function formatPrice(price, currency) {
  try {
    const numberFormatted = numberFormat(
      price,
      currency.decimalDigits,
      currency.decimalSeparator,
      currency.thousandsSeparator
    )

    if (currency.currencySymbol) {
      const priceSeparator = currency.currencySymbolNumberOfSpaces > 0
        ? ' '.repeat(currency.currencySymbolNumberOfSpaces)
        : ''
      let priceParts = [numberFormatted, priceSeparator, currency.currencySymbol]

      if (currency.currencySymbolPosition === 'left') {
        priceParts = priceParts.reverse()
      }

      return priceParts.join('')
    } else {
      return numberFormatted
    }
  } catch (e) {
    return price
  }
}

/**
* Returns the number passed formatted according to the system configurations for the currency
*
* @param number
* @returns {*}
*/
export function formatNumberHelper(number, currency) {
  try {
    const numberFormatted = numberFormat(
      number,
      currency.decimalDigits,
      currency.decimalSeparator,
      currency.thousandsSeparator
    )
    return numberFormatted;

  } catch (e) {
    return number
  }
}

/**
* Returns the percantage passed formatted according to the system configurations for the currency
*
* @param number
* @returns {*}
*/
export function formatPercentageHelper(number, currency) {
  try {
    const numberFormatted = numberFormat(
      number,
      currency.decimalDigits,
      currency.decimalSeparator,
      currency.thousandsSeparator
    )
    return `${numberFormatted}%`;

  } catch (e) {
    return `${number}%`;
  }
}

/**
   * Helper method to format a number given a few configurations such as the separation
   * between thousands and decimals
   *
   * @param number
   * @param decimals
   * @param dec_point
   * @param thousands_sep
   * @returns {*}
   */
export function numberFormat(number, decimals, dec_point, thousands_sep) {
  if (isNaN(number)) {
    return number
  }

  const negative = number < 0

  if (negative) number = number * -1

  const str = number.toFixed(decimals ? decimals : 0).toString().split('.')
  const parts: any = []

  for (let i = str[0].length; i > 0; i -= 3) {
    parts.unshift(str[0].substring(Math.max(0, i - 3), i))
  }

  str[0] = parts.join(thousands_sep ? thousands_sep : ',')

  return (negative ? '-' : '') + str.join(dec_point ? dec_point : '.')
}

/**
* Returns the current currency symbol
*
* 
* @returns {*}
*/
export function getCurrencySymbol() {
  return store.state.app.currency['currencySymbol'];
}

export default {
  formatPrice,
  numberFormat,
  getCurrencySymbol
}
