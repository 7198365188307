import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { 
    ICategoryLabState
} from './state';

import { 
    commitSetAvailableCategoryLabSchema,
    commitSetCategoryLabSchema,
    commitSetCategoryLabSchemaLoaded
} from './mutations';

import { 
    readCategoryLabSchemaLoaded
} from './getters';

// API
import { api } from '@/api';


type CategoryLabContext = ActionContext<ICategoryLabState, State>;

export const actions = {
  async loadCategoryLabSchema(context: CategoryLabContext) {
    try {
        if (!readCategoryLabSchemaLoaded(context)) {
            // load schema initially
            const responseAvailable = await api.categorylabReadSchema(context.rootState.main.token);
            if (responseAvailable.data) {
                commitSetAvailableCategoryLabSchema(context, responseAvailable.data);
                commitSetCategoryLabSchema(context, responseAvailable.data);
                commitSetCategoryLabSchemaLoaded(context, true);
            }
        }

    } catch (err) {
        //console.log(err);
    }


  },
};

const { dispatch } = getStoreAccessors<ICategoryLabState | any, State>('');

export const dispatchLoadCategoryLabSchema = dispatch(actions.loadCategoryLabSchema);

