import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import configs from '../../configs';
import { AppState } from './state';

const { product, time, theme, currencies  } = configs;

const { globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isToolbarMini, isContentBoxed, isRTL } = theme;
// const { currency, availableCurrencies } = currencies

const defaultAppState: AppState = {
  product,
  time,
  // themes and layout configurations
  globalTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isToolbarMini,
  isContentBoxed,
  isRTL,
  // App.vue main toast
  toast: {
    show: false,
    color: 'black',
    message: '',
    timeout: 3000,
  },
  currency: currencies.currency,
  availableCurrencies: currencies.availableCurrencies,
};

export const appModule = {
  name: 'app',
  namespaced: false,
  state: defaultAppState,
  getters,
  mutations,
  actions,
};
