import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';

const defaultState: MainState = {
    isLoggedIn: null,
    token: '',
    showCheatSheet: false,
    logInError: false,
    logInErrorMessage: '',
    userProfile: null,
    orgaUserProfiles: [],
    predictionsLoading: false,
    lutLoading: false,
};

export const mainModule = {
    state: defaultState,
    mutations,
    actions,
    getters,
};
