// Import Component hooks before component definitions
import './component-hooks';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';
import App from './App.vue';
import router from './router';
import store from '@/store';
import './registerServiceWorker';
import VueWordCloud from 'vuewordcloud';

import './plugins/apexcharts';
import './plugins/animate';
import './plugins/clipboard';
import './plugins/vue-number-animation';

// FILTERS
import './filters/capitalize';
import './filters/lowercase';
import './filters/uppercase';
import './filters/formatCurrency';
import './filters/formatText';
import './filters/formatDate';
import './filters/formatUserData';

// STYLES
import './assets/scss/theme.scss';

// Animation library - https://animate.style/
import 'animate.css/animate.min.css';

Vue.config.productionTip = false;
Vue.component(VueWordCloud.name, VueWordCloud);


new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
