import Vue from 'vue'

import { IUserProfile } from '@/interfaces';

Vue.filter('formatUserName', getUserName)

export function getUserName(userObj: IUserProfile): string {
  return (userObj.full_name) ? userObj.full_name : userObj.email;
}

export function getUserInitials(userObj: IUserProfile): string {
  let base: string = (userObj.full_name) ? userObj.full_name : userObj.email;
  return base.substring(0, 2);
}

export function getUserAvatar(userObj: IUserProfile): string {
  //return (userObj.avatar) ? `/images/avatars/user/${userObj.avatar}` : '/images/avatars/avatar1.svg';
  return '/images/avatars/avatar1.svg';
}

export default {
  getUserName,
  getUserInitials,
  getUserAvatar
}
