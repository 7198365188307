import en from '../translations/en';
import de from '../translations/de';

const supported = ['de', 'en']
let locale = 'de'

try {
  // get browser default language
  const { 0: browserLang } = navigator.language.split('-')

  if (supported.includes(browserLang)) locale = browserLang
} catch (e) {
  // console.log(e)
}

export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'de',

  // availabled locales for user selection
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'de',
    flag: 'de',
    label: 'Deutsche',
    messages: de
  }]
}
