import { IActionLogState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IActionProject, IActionsLifecycles } from '@/interfaces/actions';


export const mutations = {
    setActionProjects(state: IActionLogState, payload: IActionProject[]) {
        state.actionProjects = payload;
    },
    setSelectedLifecycles(state: IActionLogState, payload: IActionsLifecycles[]) {
        state.selectedLifecycles = payload;
    },
    setTotalProjects(state: IActionLogState, payload: number) {
        state.totalProjects = payload;
    },
    setProjectsPerLifecycle(state: IActionLogState, payload: object[]) {
        state.projectsPerLifecycle = payload;
    },
    setPageCount(state: IActionLogState, payload: number) {
        state.pageCount = payload;
    },
    setItemsPerPage(state: IActionLogState, payload: number) {
        state.itemsPerPage = payload;
    },
    setCurrentPage(state: IActionLogState, payload: number) {
        state.currentPage = payload;
    },
};

const {commit} = getStoreAccessors<IActionLogState | any, State>('');

export const commitSetActionProjects = commit(mutations.setActionProjects);
export const commitSetSelectedLifecycles = commit(mutations.setSelectedLifecycles);
export const commitSetTotalProjects = commit(mutations.setTotalProjects);
export const commitSetProjectsPerLifecycle = commit(mutations.setProjectsPerLifecycle);
export const commitSetPageCount = commit(mutations.setPageCount);
export const commitSetItemsPerPage = commit(mutations.setItemsPerPage);
export const commitSetCurrentPage = commit(mutations.setCurrentPage);
