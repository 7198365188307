import { api } from '@/api';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetLogInErrorMessage,
    commitSetOrgaUsers,
} from './mutations';
import { MainState } from './state';
import { dispatchSetCurrentCurrency, dispatchShowSuccessToast, dispatchShowToast } from '@/store/app/actions';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context).then(async () => {
                    await dispatchRouteLoggedIn(context);
                        dispatchShowSuccessToast(context, 'Erfolgreich eingeloggt!');
                    }
                );
            } else {
                await dispatchLogOut(context);
            }
        } catch (err: any) {
            commitSetLogInError(context, true);
            if (err.response.data.detail) {
                commitSetLogInErrorMessage(context, err.response.data.detail);
            } else {
                commitSetLogInErrorMessage(context, 'Fehler beim Einloggen');
            }
            await dispatchLogOut(context);
        }
    },
    async actionLogInAuthRoute(context: MainContext, payload: { accessToken: string; }) {
        try {
            const token = payload.accessToken;
            if (token) {
                await api.logInTestToken(token);
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                dispatchShowSuccessToast(context, 'Erfolgreich eingeloggt!');
            } else {
                await dispatchLogOut(context);
            }
        } catch (err: any) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
                dispatchSetCurrentCurrency(context, response.data.organization.transaction_options.currency);
            }
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload: any) {
        try {
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            dispatchSetCurrentCurrency(context, response.data.organization.transaction_options.currency);
            dispatchShowSuccessToast(context, 'Profil erfolgreich geändert!');
        } catch (error: any) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                    dispatchSetCurrentCurrency(context, response.data.organization.transaction_options.currency);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        dispatchShowToast(context, 'Erfolgreich ausgeloggt');
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if ([401, 403, 404].includes(payload.response!.status)) {
            await dispatchLogOut(context);
            return new Promise(() => {
                throw new Error("User nicht gefunden");
              });
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        try {
            if (router['history']['_startLocation'] && router['history']['_startLocation'] !== '/login' && router['history']['_startLocation'] !== '/') {
                router.push(router['history']['_startLocation']).catch(() => {
                    // pass
                });
            } else {
                if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
                    router.push('/main').catch(() => {
                        // pass
                    });
                }
            }
        } catch {
            if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
                router.push('/main').catch(() => {
                    // pass
                });
            }
        }
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        try {
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            dispatchShowSuccessToast(context, 'E-Mail zur Wiederherstellung des Passworts gesendet');
            await dispatchLogOut(context);
        } catch (error) {
            dispatchShowToast(context, 'Falscher Username');
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        try {
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            dispatchShowSuccessToast(context, 'Passwort erfolgreich zurückgesetzt');
            await dispatchLogOut(context);
        } catch (error) {
            dispatchShowToast(context, 'Fehler beim Zurücksetzen des Passworts');
        }
    },
    async orgaUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetOrgaUsers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error as AxiosError);
        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogInAuthRoute = dispatch(actions.actionLogInAuthRoute);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchSetOrgaUsers = dispatch(actions.orgaUsers);
