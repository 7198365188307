import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { IInsightsState } from './state';

const defaultInsightsState: IInsightsState = {
    insightsTypes: [],
    insightsTypesLoaded: false,
    insightsData: [],
    insightsFavouritesLoading: false,
};

export const insightsModule = {
    name: 'insights',
    namespaced: false,
    state: defaultInsightsState,
    getters,
    mutations,
    actions,
};
