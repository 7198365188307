import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { IActionLogState } from './state';

const defaultActionLogState: IActionLogState = {
    actionProjects: [],
    selectedLifecycles: ['ALL'],
    dataLoaded: true,
    projectsPerLifecycle: [],
    totalProjects: NaN,
    pageCount: NaN,
    itemsPerPage: 10,
    currentPage: 1,
};

export const actionLogModule = {
    name: 'actionlog',
    namespaced: false,
    state: defaultActionLogState,
    getters,
    mutations,
    actions,
};
