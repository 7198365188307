import { AppState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    product: (state: AppState) => state.product,
    time: (state: AppState) => state.time,
    currency: (state: AppState) => state.currency,
    availableCurrencies: (state: AppState) => state.availableCurrencies,
    globalTheme: (state: AppState) => state.globalTheme,
    menuTheme: (state: AppState) => state.menuTheme,
    toolbarTheme: (state: AppState) => state.toolbarTheme,
    isToolbarDetached: (state: AppState) => state.isToolbarDetached,
    isToolbarMini: (state: AppState) => state.isToolbarMini,
    isContentBoxed: (state: AppState) => state.isContentBoxed,
    isRTL: (state: AppState) => state.isRTL,
    toast: (state: AppState) => state.toast,
};

const {read} = getStoreAccessors<AppState, State>('');

export const readProduct = read(getters.product);
export const readTime = read(getters.time);
export const readCurrency = read(getters.currency);
export const readAvailableCurrencies = read(getters.availableCurrencies);
export const readGlobalTheme = read(getters.globalTheme);
export const readMenuTheme = read(getters.menuTheme);
export const readToolbarTheme = read(getters.toolbarTheme);
export const readIsToolbarDetached = read(getters.isToolbarDetached);
export const readIsToolbarMini = read(getters.isToolbarMini);
export const readIsContentBoxed = read(getters.isContentBoxed);
export const readIsRTL = read(getters.isRTL);
export const readToast = read(getters.toast);
