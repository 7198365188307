import { IUserProfile } from '@/interfaces';
import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setLogInErrorMessage(state: MainState, errorMessage: string) {
        state.logInErrorMessage = errorMessage;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setPredictionsLoading(state: MainState, payload: boolean) {
        state.predictionsLoading = payload;
    },
    setLUTLoading(state: MainState, payload: boolean) {
        state.lutLoading = payload;
    },
    setOrgaUsers(state: MainState, payload: IUserProfile[]) {
        state.orgaUserProfiles = payload;
    },
    // Docs
    setShowCheatSheet(state: MainState, payload: boolean) {
        state.showCheatSheet = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetLogInErrorMessage = commit(mutations.setLogInErrorMessage);
export const commitSetToken = commit(mutations.setToken);
export const commitSetShowCheatSheet = commit(mutations.setShowCheatSheet);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetPredictionsLoading = commit(mutations.setPredictionsLoading);
export const commitSetLUTLoading = commit(mutations.setLUTLoading);
export const commitSetOrgaUsers = commit(mutations.setOrgaUsers);
