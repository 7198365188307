// insights interfaces

import { IActionsTemplate } from "./actions";

// configs

export const insightsConfig: {[key: string]: IInsightConfig} = {
    insight_spend_trend: {
        name: 'Steigender Bedarf erkannt',
        nameShort: 'Steigender Bedarf',
        description: 'Die beobachtete Auffälligkeit ist ein deutlicher Anstieg der Nachfrage und der Ausgaben bei einem bestimmten Lieferanten oder innerhalb einer bestimmten Warengruppe. Das bedeutet, dass bei dieser Lieferant oder in diese Warengruppe im Vergleich zu anderen Lieferanten oder Kategorien überproportional stärker relevant wird.',
        importance: 'Erstens deutet die Auffälligkeit auf eine mögliche übermäßige Abhängigkeit von einem einzigen Lieferanten hin, was für das Unternehmen ein erhebliches Risiko darstellen kann. Wenn es bei diesem Lieferanten zu Störungen oder Qualitätsproblemen kommt, könnte dies zu Unterbrechungen der Lieferkette führen und die Fähigkeit des Unternehmens beeinträchtigen, seine operativen Anforderungen zu erfüllen. Darüber hinaus verringert eine hohe Konzentration der Ausgaben auf einen einzigen Lieferanten die Verhandlungsposition des Unternehmens und schränkt den Wettbewerb ein, was zu ungünstigen Preisen und Vertragsbedingungen führen kann.',
        benefits: 'Durch die Diversifizierung der Lieferantenbasis und die Verteilung der Ausgaben auf mehrere Lieferanten oder Warengruppen kann das Unternehmen die mit der Abhängigkeit von einer einzigen Quelle verbundenen Risiken minimieren. Dies kann die Widerstandsfähigkeit der Lieferkette erhöhen und die Kontinuität der Versorgung sicherstellen. Außerdem kann der Einkauf durch den verstärkten Wettbewerb unter den Lieferanten wettbewerbsfähigere Preise und günstigere Bedingungen aushandeln, was zu Kosteneinsparungen führt. Und schließlich kann eine ausgewogenere Verteilung der Ausgaben die Innovation anregen, die Entwicklung von Lieferanten fördern und Möglichkeiten für die Zusammenarbeit und Partnerschaften mit einem breiteren Spektrum von Lieferanten schaffen.',
        icon: 'mdi-finance',
        tableValueHeader: 'Steigung',
        tableValueHeaderTooltip: 'Dieser Faktor sagt aus, wie stark der Spend im aktuell gefilterten Zeitraum gestiegen ist.',
        insightPlaceholderExplaination: 'Der Spend von {{ referenceToX }} {{ name }} ist um den Faktor {{ value }} gestiegen',
        insightPlaceholderTopExplaination: 'Spend von {{ referenceToX }} {{ name }} ist stark gestiegen, um den Faktor {{ value }}. Potenzial für einkäuferische Prüfung.',
        insightExplainationValueRounding: 0,
        targets: [
            {
                target_name: 'Kosten senken',
                target_description: 'Bessere Preise innerhalb dieser Sub-Warengruppe erzielen.',
            },
            {
                target_name: 'Risiken minimieren',
                target_description: 'Versorgung und Lieferqualität sicherstellen.',
            },
            {
                target_name: 'Standardisierung',
                target_description: 'Sichergehen, dass die Bedarfe unternehmensweit einheitlich und bei den richtigen Lieferanten beschafft werden.',
            },
        ],
        actions: [
            'tender',
            'negotiation',
            'vendorConsolidation',
            'bundling',
            'standardization',
            'supplierEvaluation',
            'categoryStrategies',
        ],
    },
    insight_number_different_X_per_vendor: {
        name: 'Selber Lieferant in verschiedenen Bereichen erkannt',
        nameShort: 'Lieferant in verschiedenen Bereichen',
        description: 'Die beobachtete Auffälligkeit liegt darin, dass derselbe Lieferant Spend in mehreren Bereichen aufweist, etwa in mehreren Buchungskreisen oder Warengruppen. Der Lieferant ist damit im Unternehmen weit verbreitet. Möglicherweise wird mit dem Lieferanten noch nicht zentral und zu einheitlichen Bedingungen verhandelt. Der Einkauf hat die Möglichkeit, die eigene Verhandlungsposition besser auszunutzen, da das eigene Unternehmen seitens des Lieferanten ein wichtiger Kunde ist.',
        importance: 'Zum einen kann diese Auffälligkeit darauf hindeuten, dass eine starke Abhängigkeit zu dem Lieferanten besteht. Ein potenzieller Ausfall würde mehrere Bereiche betreffen und somit zu tiefen Einschnitten führen. Die verbreitete Tätigkeit des Lieferanten deutet zum anderen darauf hin, dass großes Potenzial zur Kostenoptimierung besteht. Der Lieferant wird ein großes Interesse an guten und langfristigen Beziehungen haben, dieser Punkt kann in Verhandlungen angesprochen werden.',
        benefits: 'Eine strategische Zusammenarbeit und zentrale Verhandlungen mit dem Lieferanten führen zu besseren Preisen und einheitlichen Konditionen. Das Risiko in der Fokussierung auf diesen Lieferanten kann zudem besser eingeschätzt werden und der Verwaltungsaufwand mit dem Lieferanten wird verringert.',
        icon: 'mdi-map-marker-distance',
        tableValueHeader: '# {{ referenceToXPlural }}',
        tableValueHeaderTooltip: 'Dieser Faktor sagt aus, bei wie vielen {{ referenceToXPlural }} der Lieferant tätig ist.',
        insightPlaceholderExplaination: 'Lieferant {{ name }} ist tätig in {{ value }} {{ referenceToXPlural }}.',
        insightPlaceholderTopExplaination: 'Lieferant {{ name }} ist mit {{ value }} {{ referenceToXPlural }} überdurchschnittlich oft vertreten. Potenzial für Bündelung.',
        insightExplainationValueRounding: 0,
        targets: [
            {
                target_name: 'Konditionen verbessern',
                target_description: 'Bessere Preise in Verhandlungen mit dem Lieferanten erzielen.',
            },
            {
                target_name: 'Interne Aufwände reduzieren',
                target_description: 'Spart administrative Aufwände und damit Prozesskosten.',
            },
        ],
        actions: [
            'negotiation',
            'bundling',
            'contractConditions',
            'standardization',
            'supplierEvaluation',
        ],
    },
    insight_number_vendors_per_X: {
        name: 'Hohe Lieferantenanzahl erkannt',
        nameShort: 'Hohe Lieferantenanzahl',
        description: 'In den Spend-Daten fällt auf, dass es eine hohe Lieferantenanzahl im Verhältnis zu dem zugehörigen Spend gibt. Das Spend-Volumen ist also auf viele einzelne Lieferanten aufgeteilt und eher gleichverteilt, als dass es stark dominierende einzelne Lieferanten gibt. Je nach strategischer Ausrichtung in einer Warengruppe, kann dies entweder gewollt sein oder aber auch ein Ergebnis von historisch gewachsenen Strukturen. In jedem Fall lohnt sich eine detailliertere Untersuchung.',
        importance: 'Zu viele verschiedene Lieferanten in einem Bereich können nicht nur hohe administrative Aufwände verursachen, sondern bedeuten häufig auch, dass keine optimalen Konditionen erzielt werden. Eine Bündelung des Spends bei weniger Lieferanten erhöht die Einkaufsmacht und Lieferanten sind aufgrund von höherem Umsatz eher bereit gute Konditionen zu bieten. Auf der anderen Seite kann eine zu hohe Konzentration auf wenige Lieferanten die Beschaffungsrisiken erhöhen – insbesondere bei strategisch wichtigen Gütern. Hier gilt es also eine gute Abwägung zu finden.',
        benefits: 'Die Vorteile einer Lieferantenreduktion bestehen hauptsächlich aus einer Kostenreduktion und einer damit möglichen strategischeren und intensiveren Zusammenarbeit mit weniger Lieferanten. Nicht zu unterschätzen ist jedoch auch, dass die Fokussierung auf eine geringere Lieferantenzahl maßgeblich administrative Aufwände (wie die Lieferantenbewertung, Zertifizierungen, Audits…) verringert.',
        icon: 'mdi-human-capacity-decrease',
        tableValueHeader: '# Lieferanten',
        tableValueHeaderTooltip: 'Gibt an, wie viele Lieferanten Spend bei diesem {{ referenceToX }} haben',
        insightPlaceholderExplaination: 'Bei {{ referenceToX }} {{ name }} weisen {{ value }} Lieferanten Spend auf.',
        insightPlaceholderTopExplaination: '{{ referenceToX }} {{ name }} hat mit {{ value }} Lieferanten überdurchschnittlich viele Lieferanten.',
        insightExplainationValueRounding: 0,
        targets: [
            {
                target_name: 'Kosten senken',
                target_description: 'Bessere Preise für innerhalb dieser Sub-Warengruppe erzielen.',
            },
            {
                target_name: 'Lieferanten reduzieren',
                target_description: 'Spart administrative Aufwände, ermöglicht aber auch bessere einzelne Lieferantenbeziehungen und Konditionen.',
            },
        ],
        actions: [
            'vendorConsolidation',
            'bundling',
            'tender',
            'standardization',
            'supplierEvaluation',
            'categoryStrategies',
        ],
    },
    insight_spend_per_transaction_X: {
        name: 'Zahlreiche Einzelprozesse erkannt',
        nameShort: 'Zahlreiche Einzelprozesse',
        description: 'Die Auffälligkeit zeigt eine hohe Anzahl von Transaktionen in einer bestimmten Warengruppe oder bei einem Lieferanten innerhalb des Einkaufsprozesses. Das bedeutet, dass in dieser Warengruppe oder bei diesem Lieferanten im Vergleich zu anderen Bereichen eine große Anzahl an Bestellungen oder Rechnungen und damit Einzelprozessen generiert wird.',
        importance: 'Erstens führt sie zu ineffizienten Prozessen, da das hohe Volumen an Vorgängen zu Engpässen führt und die Arbeitsbelastung der Mitarbeitenden erhöht. Es wird zu einer Herausforderung, eine so große Anzahl von Bestellungen oder Rechnungen zuzu bearbeiten, was zu Verzögerungen, Fehlern und potenzieller Nacharbeit führt.        Zweitens könnte die hohe Anzahl von Transaktionen auf eine mangelnde Standardisierung und Konsolidierung der Praktiken im Einkauf hindeuten. Das bedeutet, dass möglicherweise ähnliche Waren oder Dienstleistungen bei verschiedenen Lieferanten separat bestellt werden, anstatt diese Bestellungen zu konsolidieren und Skaleneffekte zu nutzen oder bessere Konditionen mit bevorzugten Lieferanten auszuhandeln.',
        benefits: 'Der Einkauf kann durch die Konsolidierung von Bestellungen Prozessekosten sparen , durch die Nutzung von Skaleneffekten Kosteneinsparungen erzielen und potenziell bessere Bedingungen mit den Lieferanten aushandeln. Und nicht zuletzt wird die Beseitigung dieser Auffälligkeit auch zur Datengenauigkeit und -integrität beitragen. Durch die Verringerung des Transaktionsvolumens und die Gewährleistung der Standardisierung wird die Genauigkeit der Spend-Daten verbessert, was eine bessere Analyse und fundierte Entscheidungsfindung  ermöglicht.',
        icon: 'mdi-chart-scatter-plot',
        tableValueHeader: 'Spend / Transaktion',
        tableValueHeaderTooltip: 'Gibt den durchschnittlichen Spend pro Transaktion an. Ein niedriger Wert ist ein Indikator für eine mögliche Prozessoptimierung.',
        insightPlaceholderExplaination: 'Bei {{ referenceToX }} {{ name }} liegt der Spend pro Transaktion bei {{ value }}.',
        insightPlaceholderTopExplaination: '{{ referenceToX }} {{ name }} weist sehr viele Einzelprozesse mit geringem Spend auf, der Spend pro Transaktion liegt bei {{ value }}.',
        insightExplainationValueRounding: 0,
        targets: [
            {
                target_name: 'Interne Aufwände reduzieren',
                target_description: 'Spart administrative Aufwände und damit Prozesskosten.',
            },
            {
                target_name: 'Durchlaufzeit von Prozessen optimieren',
                target_description: 'Einzelne Transaktionen laufen schneller ab, führt zu besserer Mitarbeiterzufriedenheit und schnellerer Handlungsfähigkeit.',
            },
        ],
        actions: [
            'processBundling',
            'eProcurement',
            'catalogues',
        ],
    },
    insight_diff_set_of_vendors_over_X_for_Y: {
        name: 'Potenzial für geschäftsbereichsübergreifende Volumenbündelung (selbe Warengruppe)',
        nameShort: 'Bündelungspotenzial Warengruppe',
        icon: 'mdi-sitemap',
        tableValueHeader: 'Wert',
        tableValueHeaderTooltip: '',
        insightPlaceholderExplaination: '',
        insightPlaceholderTopExplaination: '',
        targets: [
            {
                target_name: 'Konditionen verbessern',
                target_description: 'Ein klassisches Ziel für den Einkauf...',
            },
            {
                target_name: 'Interne Aufwände reduzieren',
                target_description: 'Spart administrative Aufwände, ermöglicht aber auch bessere einzelne Lieferantenbeziehungen und Konditionen.',
            },
        ],
    }
};

export const insightsConfigSingle: {[key: string]: IInsightConfigSingle} = {
    insight_spend_trend_vendor: {
        parentInsight: 'insight_spend_trend',
        referenceToX: 'Lieferant',
        referenceToXPlural: 'Lieferanten',
    },
    insight_spend_trend_category_hier_1: {
        parentInsight: 'insight_spend_trend',
        referenceToX: 'Warengruppe Level1',
        referenceToXPlural: '',
    },
    insight_spend_trend_category_hier_2: {
        parentInsight: 'insight_spend_trend',
        referenceToX: 'Warengruppe Level2',
        referenceToXPlural: '',
    },
    insight_number_different_factories_per_vendor: {
        parentInsight: 'insight_number_different_X_per_vendor',
        referenceToX: 'Werk',
        referenceToXPlural: 'Werke',
    },
    insight_number_different_company_codes_per_vendor: {
        parentInsight: 'insight_number_different_X_per_vendor',
        referenceToX: 'Buchungskreis',
        referenceToXPlural: 'Buchungskreis(en)',
    },
    insight_number_different_categories_hier_1_per_vendor: {
        parentInsight: 'insight_number_different_X_per_vendor',
        referenceToX: 'Warengruppe Level1',
        referenceToXPlural: 'Level1 Warengruppen',
    },
    insight_number_different_categories_hier_2_per_vendor: {
        parentInsight: 'insight_number_different_X_per_vendor',
        referenceToX: 'Warengruppe Level2',
        referenceToXPlural: 'Level2 Warengruppen',
    },
    insight_number_vendors_per_category_hier_1: {
        parentInsight: 'insight_number_vendors_per_X',
        referenceToX: 'Warengruppe Level 1',
        referenceToXPlural: '',
    },
    insight_number_vendors_per_category_hier_2: {
        parentInsight: 'insight_number_vendors_per_X',
        referenceToX: 'Warengruppe Level 2',
        referenceToXPlural: '',
    },
    insight_number_vendors_per_factory: {
        parentInsight: 'insight_number_vendors_per_X',
        referenceToX: 'Werk',
        referenceToXPlural: 'Werk(en)',
    },
    insight_number_vendors_per_company_code: {
        parentInsight: 'insight_number_vendors_per_X',
        referenceToX: 'Buchungskreis',
        referenceToXPlural: 'Buchungskreis(en)',
    },
    insight_spend_per_transaction_vendor: {
        parentInsight: 'insight_spend_per_transaction_X',
        referenceToX: 'Lieferant',
        referenceToXPlural: 'Lieferant(en)',
    },
    insight_spend_per_transaction_category_hier_1: {
        parentInsight: 'insight_spend_per_transaction_X',
        referenceToX: 'Warengruppe Level 1',
        referenceToXPlural: '',
    },
    insight_spend_per_transaction_category_hier_2: {
        parentInsight: 'insight_spend_per_transaction_X',
        referenceToX: 'Warengruppe Level 2',
        referenceToXPlural: '',
    },
    insight_diff_set_of_vendors_over_factories_for_category_hier_1: {
        parentInsight: 'insight_diff_set_of_vendors_over_X_for_Y',
        referenceToX: 'Werk',
        referenceToXPlural: '',
        referenceToY: 'Warengruppe Level 1',
    },
    insight_diff_set_of_vendors_over_factories_for_category_hier_2: {
        parentInsight: 'insight_diff_set_of_vendors_over_X_for_Y',
        referenceToX: 'Werk',
        referenceToXPlural: '',
        referenceToY: 'Warengruppe Level 2',
    },
    insight_diff_set_of_vendors_over_company_codes_for_category_hier_1: {
        parentInsight: 'insight_diff_set_of_vendors_over_X_for_Y',
        referenceToX: 'Buchungskreis',
        referenceToXPlural: '',
        referenceToY: 'Warengruppe Level 1',
    },
    insight_diff_set_of_vendors_over_company_codes_for_category_hier_2: {
        parentInsight: 'insight_diff_set_of_vendors_over_X_for_Y',
        referenceToX: 'Buchungskreis',
        referenceToXPlural: '',
        referenceToY: 'Warengruppe Level 2',
    },
};

export const insightsFavourites: string[] = [
    'insight_spend_trend_vendor',
    'insight_number_vendors_per_category_hier_2',
    'insight_number_vendors_per_company_code',
    'insight_number_different_company_codes_per_vendor',
    //'insight_spend_per_transaction_category_hier_2',
    'insight_spend_per_transaction_vendor',
];

export interface IInsightConfigTargets {
    target_name: string,
    target_description: string,
}

export interface IInsightConfigApproaches {
    approach_name: string,
    approach_description: string,
    approach_checklist: string[],
}

export interface IInsightConfig {
    name: string,
    nameShort: string,
    description?: string,
    importance?: string,
    benefits?: string,
    icon: string,
    tableValueHeader: string,
    tableValueHeaderTooltip: string,
    insightPlaceholderExplaination: string,
    insightPlaceholderTopExplaination: string,
    insightExplainationValueRounding?: number,
    targets?: IInsightConfigTargets[],
    actions?: string[], // reference to IActionsTemplate.internalName
}

export interface IInsightConfigSingle {
    parentInsight: string,
    referenceToX: string,
    referenceToXPlural: string,
    referenceToY?: string,
}

// results / Data

export interface IInsightsResult {
    _id: string,
    name: string,
    identifier: string,
    value: number,
    explainationString?: string,
}

export interface IInsightsData {
    insight: string,
    dataLoading: boolean,
    dataLoaded: boolean,
    expanded: boolean,
    data: IInsightsResult[],
}

// top insights

export interface ITopInsights {
    insight: string,
    data: IInsightsResult;
}